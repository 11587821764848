#frmPhoneSend{
  fieldset{
    .form-group{
  
      #ticket_attachment{
        @include media-breakpoint-down(lg){
          width: 90%;
        }
        @include media-breakpoint-up(xl){
          width: 100%;
        }
      }
    }
  }
}


/******************************************************************************
 * Quickbuy modal
 ******************************************************************************/
.product-details {
  header {
  //  border-bottom: 1px solid $gray-400;

    .name {
      color: $primary;
    }
  }
  a.thumbnail {
    display: block;

    //  &.active {
    //  border-bottom: 5px solid;
    //  }
  }
  .category-sticker {
    left: 10px;
    position: absolute;
    top: 0;
    z-index: $zindex-dropdown;
  }

  .product-filters {
    .btn {
      &.image-option {
        padding: 0;
      }
    }
		.image-option{
			&:hover{
				text-decoration:none;
			}
			img{
				border-radius: 14px;
				overflow: hidden;
				padding: 0;
				width: 28px;
				height: 28px;
				display: inline-block;
				border: 1px solid #ccc;
			}
			&.active{
				img{
					border: 1px solid #000000;
				}
			}
		}
  }

  #zoomMain, .zoom-main {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }
  }
}

#shopbylookItems {
  article {
    .sticker {
      position: absolute;
    }
  }
}

/******************************************************************************
 * Category page and related products
 ******************************************************************************/
.product-preview {
  article {
    //height: calc(100% - 2em);
    //@extend .pt-3;

    &:hover {
      //box-shadow: 0px 0px 5px $gray-400;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
  }
  .product-content {
    position: relative;
    .sticker {
      position: absolute;
      top: 0;
      z-index: $z-index-100;
    }

    .btn-compare-quickbuy {
      display: none;
      padding: 0.1em 0.4em;
      position: absolute;
      right: 1em;
      top: 0;
      z-index: 1;
    }

    .images-container {
			.container-btn-wishlist {
				display: none;
				position: absolute;
				right:-30px;
        z-index: 98;
				//top:0.5rem;
				
				/*@include media-breakpoint-up(sm) {
					display: none !important;
				}*/
				.btn{
					color:#ffffff;
				}
			}
      .container-btn-quickbuy {
        display: none;
        left: 32px;
        position: absolute;
        bottom:16px;
        
      }

      img {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;

        @include transition(opacity 0.4s ease-out);
      }

      img + img {
        position: absolute;
        top: 50%;
        opacity: 0;
        right: 50%;
        transform: translate(50%, -50%);
      }

      &:hover {
        img{
          //opacity: 0;
          //transition: transform .6s ease-in-out;
          //transform: scale(1.1);
        }
        img + img {
          opacity: 1;
        }
				.container-btn-wishlist {
					 display: block;
				}
        .container-btn-quickbuy {
          display: block;
        }
      }
    }

    &:hover {
      .btn-compare-quickbuy {
        display: block;
      }
    }
  }

  .product-options {
    ul {
      li {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .btn {
      padding: ($line-height-base / 4);
      line-height: $line-height-base;
    }

    img {
      border: 1px solid $gray-600;
      display: block;
      height: 1.5rem;
    }
  }
  .btn-group-justified {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .btn-group {
      flex-grow: 1;
    }
  }
}

.featured-products {
  @extend .align-items-center;
  @extend .d-flex;
  @extend .h3;
  @extend .text-center;
  @extend .text-primary;
}

.h2.price.font-ingra-wd{
  font-weight: 600;
}


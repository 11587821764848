html{
	scroll-behavior: smooth;
}

.font-16{
	font-size:$font-size-base;
}

b, strong, .bold{
	font-weight:$font-weight-bold;
}

.font-ingra-wd{
	font-family: $font-family-ingra-wide!important;
}

.font-weight-100{font-weight: 100!important;}
.font-weight-200{font-weight: 200!important;}
.font-weight-300{font-weight: 300!important;}
.font-weight-400{font-weight: 400!important;}
.font-weight-500{font-weight: 500!important;}
.font-weight-600{font-weight: 600!important;}
.font-weight-700{font-weight: 700!important;}
.font-weight-800{font-weight: 800!important;}
.font-weight-900{font-weight: 900!important;}

.text-white{color: $white;}

@include media-breakpoint-down(md){
	h1,.h1{font-size: 2.25rem;}
}
@include media-breakpoint-down(sm){
	h1,.h1{font-size: 1.5rem;}
}

@include media-breakpoint-up(md){
	.padding-left{padding-left:calc(5% + .938rem);}
	.padding-right{padding-right:calc(5% + .938rem);}
}


@include media-breakpoint-down(md) {
	body.stop-scrolling{
		height: 100%;
		overflow: hidden;
		position: fixed;
		top:0;
		left:0;
		right:0;
	}
}

[data-zg-role="payment-tab"].active{
	label.custom-control-label{
		&::after{
			background-image: $custom-radio-indicator-icon-checked;
		}
		&::before {
			border-color: $black;
    		background-color: $black;
		}
	}
}
#accordionPaymentMethodInfo{
	&.accordion>.card:first-of-type{
		border:1px solid rgba(0,0,0,.125);
	}
}

/* Navigation nav-pills for desktop and collapse for mobile */
@include media-breakpoint-up(lg){
	.nav-pills-collapse{
		font-size:14px;
		.nav-link{
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
			padding:24px 14px 24px 0;
			//border-right: 1px solid $primary;
			color:$primary;
			font-weight: 400;
			position: relative;
			text-align: right;
			&::after{
				position: absolute;
				content: "";
				height: 10px;
				background-color:transparent;
				width: 10px;
				right: -5px;
				border-radius: 50%;
				top: 24px;
			}
			&:first-child{ padding-top:0;
				&::after{
					top:0;
				}
			}

			&:last-child{ padding-bottom:0;
				&::after{
					top:auto;
					bottom: 0;
				}
			}
			&.active{
				background-color:transparent;
				color:$primary;
				&::after{
					background-color: #F89E38;
				}
			}
		}
	}
}
@include media-breakpoint-down(md){
	.nav-pills-collapse{
		.nav-link{
			text-transform: uppercase;
			text-align: center;
			padding: 20px 0;
			border-bottom: 1px solid;
			position:relative;
			&:after{
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				line-height: 1;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f054";
				position: absolute;
				top: 1.4rem;
				right: 1rem;
				transition-duration: 0.8s;
				transition-property: transform;
			}
			&.active{
				&:after{
					transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
				}
			}
		}
	}
}

/* ============================================ *
 * Progress bar
 * ============================================ */
.progressBarCnt{
	background: #f3f7fa;
    padding: 8px 16px;
    font-size: 14px;
}

.progressbar_main{
	border-radius:12px;
	background-color: #aaa;
	height: 20px;
	width: 100%; 
	margin: auto;
}

.progressbar_main-done{
	border-radius:12px;
	height:100%;
	width:30px;
	background: $primary;
}

/* ============================================ *
 * Custom scrollbars
 * ============================================ */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: $primary; }

::-webkit-scrollbar-track {
  background: $white }

/* ============================================ */

.input-group{
	.input-group-btn,.input-group-append{
		/*.btn{
		max-height: 35px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 11px;
		}*/
	}
}

.btn-transparent{
	color: #fff;
    background-color: transparent;
    border-color: #ffffff;
}

#help-area {
	&.nav-pills-collapse{
		.nav-link{
			font-size: 1.063rem;
			line-height: 1.25rem;
		}
	}
	#v-pills-tab{
		a.nav-link{
			margin: 8px 0px;
			background-color: #F2F6F5;// #FF9E38;
			height: 56px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0px 20px;
			color: #0C4365;
			border-radius: 16px;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			text-transform: capitalize !important;
			.icon{
				    width: 20px;
					height: 20px;
			}
			.icon-arrow{
				background-image: url("../../images/icons/chevron-right.svg");
				background-repeat: no-repeat;
			}
			&:active{
				.icon-arrow{
					background-image: url("../../images/icons/chevron-right-blue.svg");
					background-repeat: no-repeat;
				}
			}
		}
		.nav-link.active{
			background-color:  #FF9E38;
				.icon-arrow{
					background-image: url("../../images/icons/chevron-right-blue.svg");
					background-repeat: no-repeat;
				}
		}
	}	
	.nav-pills-collapse .nav-link.active::after{
		background-color: transparent;
	}	
	#v-pills-tabContent	{
		.subtitle{
			font-weight: bold;
			font-size: 28px;
			line-height: 40px;
		}
	}

}

#help-area {
	.card-body {
		border-radius: 0px!important;
	}
	@include media-breakpoint-down(xs){
		.nav-pills-collapse{
			.card{
			border: none;
			}
		}
	}
	.nav-pills-collapse{
		.mobile-help{
			.dropdown{
				.dropdown-menu{
					.dropdown-item.active{
						background-color: transparent !important;
						color: #212529;
					}
				}
			}
			
		}
	}
}


// search 
.navbar-tool{
	#navbarSearch{
		 .dropdown-menu{
			position: fixed;
			width: 100vw;
			top: 111px;
			left: 0;
			border: none;
			border-left: none;
			border-right: none;
			padding: 1rem 1.375rem;
			@include media-breakpoint-down(xs){
				top: 62px;
			}
			/* ipad portrait */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				top: 87px;
			}
			/* ipad Landscape */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: landscape) 
			and (-webkit-min-device-pixel-ratio: 1) {
				top: 87px;
			}
			ul.typeahead{
			top: 33px;
			width: 630px;
			position: absolute;
			display: none!important;
			@include media-breakpoint-down(xs){
			width: 312px;
			}
			}
		 }
		 .top-notification{
			 top: 141px !important;
			@include media-breakpoint-down(xs){
				top: 92px !important;
			}
			/* ipad portrait */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				top: 117px !important;
			}
			/* ipad Landscape */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: landscape) 
			and (-webkit-min-device-pixel-ratio: 1) {
				top: 117px !important;
			}
		 }
		 .search-input{
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			font-weight: 300;
			font-style: italic;
			text-transform: uppercase;
			//color: black;
				&:focus{
					background-color: white;
				}
			}
			&::-moz-placeholder { /* Firefox 19+ */
			font-weight: 300;
			font-style: italic;
			text-transform: uppercase;
			}
		 }
	}
} 

#product-finder{
	input[type=text]:focus{
		background-color: white;
	}
}

.border-bottom-black{
	border-bottom: 1px solid #000000!important;
}


.modal-newsletter{
	    max-width: 383px !important;
			@include media-breakpoint-down(xs){
				max-width: 283px !important;
				margin: 3rem auto;
			}
		.modal-header-newsletter{
			display: block;
		}
		#content_newsletter_modal{
			.container{
				padding: 0px;
			}
		}

}

.modal-border{
	   border: 2px solid #7a7a7a;
}
/*
.filter-name {
	font-size: 0,75rem!important;
	line-height: 0,938rem!important;
	font-weight: 300!important;
	margin-bottom: 1.5rem;
	    margin-top: 1rem!important;
		overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
*/

.info-block{
	font-size:12px;
    display: block;
    color:$primary;
    padding-right: 10px;
}
.help-block{
	font-size:12px;
    display: block;
    color:$red;
    padding-right: 10px;
}


.card-return{
	border: none !important;
}

/*.modal-quickbu{
	    max-width: 450px;
}*/
#quickbuy [data-zg-role=add-to-cart]{
	/* ipad portrait */
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
		font-size: 0.875rem !important;
		padding: 20px;
	}
	@include media-breakpoint-down(xs){
		padding: 20px;
	}
}
#quickbuy{
	.wish-col{
			@include media-breakpoint-down(xs){
					margin-top: 25px !important;
			}
							/* ipad portrait */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				margin-top: 16px !important;
			}
			/* ipad Landscape */
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: landscape) 
			and (-webkit-min-device-pixel-ratio: 1) {
				margin-top: 16px !important;
			}
	}
}

.fa-exchange-alt{
	@include media-breakpoint-down(xs){
	padding-top: 5px;
	}
}

.modal-quickbuy{
	/* ipad portrait */
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
			max-width: 700px !important;
	}
	/* ipad Landscape */
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: landscape) 
	and (-webkit-min-device-pixel-ratio: 1) {
			max-width: 700px !important;
	}
}

.jumbotron-text-error{
	top: 45%;
    left: 31%;
    transform: translate(-50%);
				@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				 top: 40%;
				 left: 50%;
			}
	@include media-breakpoint-down(sm){
		top: 35%;
		left: 50%;
	}
}

.img-404 {
	border-radius: 68px;
}

.caption-banner {
	position: absolute;
    right: 0;
    bottom: 0;
	top: 1106px;
    left: 0;
    z-index: 10;
    color: #fff;
    text-align: center;
}


//*** HP COMPONENTS START ***//

.icon-component {
	margin-top: 60px;
	margin-bottom: 60px;
	text-align: center;

	.title-icon {
		font-size: 3.5rem;
		line-height: 4.5rem;
		font-weight: 400;

		@include media-breakpoint-down(sm){
		font-size: 2rem!important;
		line-height: 1.8rem;
	}
	}

	.description-container {
		font-size: 1.25rem;
		line-height: 1.75rem;
		font-weight: 300;
	}

	.description-icon {
		font-size: 1.25rem;
		line-height: 1.75rem;
		font-weight: 400;
	}
	
}


.gender-component {
	.caption-gender {
		position: absolute;
		bottom: 4rem;
		left: 4rem;
		@include media-breakpoint-down(md){
			bottom: 1rem;
			left: 2rem;
		}

		.caption-text {
			color: #ffffff;
			font-weight: 500;
			font-size: 3.5rem;
			line-height: 4.5rem;
			text-decoration: none;
			
			@include media-breakpoint-down(sm){
				font-size: 2rem!important;
				line-height: 2rem;
			}
		}
	}
}

.banner-component {
	color: #ffffff;
	.banner-caption {
		position: absolute;
		bottom: 4rem;
		left: 4rem;
		@include media-breakpoint-down(md){
			bottom: 1rem;
			left: 2rem;
		}
	}
}



.text-carousel-caption {
	position: absolute;
    right: 0;
    top: 11rem;
    left: 0;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 0;
    text-align: center;

	@include media-breakpoint-down(sm){
		top: 6rem;
	}

	.slider-description {
		font-weight: 500;
		font-size: 3.5rem;
		line-height: 4.5rem;

		@include media-breakpoint-down(sm){
			font-size: 2rem;
			line-height: 2rem;
		}
		
	}
}

.carousel-indicators-text {
	position: absolute;
    right: 49%;
    bottom: 29%;
    z-index: 15;
    display: flex;
    justify-content: right;
    padding-left: 0;
    list-style: none;

	li {
		box-sizing: content-box;
    	flex: 0 1 auto;
		width: 10px;
    	height: 10px;
		border-radius: 50%;
    	margin-right: 3px;
    	margin-left: 3px;
    	text-indent: -999px;
    	cursor: pointer;
    	background-color: #fff;
    	background-clip: padding-box;
    	border: 1px solid #003A70;
		@include transition($carousel-indicator-transition);

		.active {
		background-color: #003A70;
	}
	}

	
}
.cart-qty-selector{
	input.form-control{
		background-color: $white;
	}
	.btn-style{
		padding: 0 4px;
		border-radius: 0;
		font-size: 12px;
	}
}

.btn-outline-slider {
 color: #ffffff;
 border-color: #ffffff;

	@include media-breakpoint-down(sm){
		padding: 0.4rem 0.9rem;
		font-size: 0.8rem;
		line-height: 1rem;
	}
}

.btn-outline-slider:hover {
    color: #fff;
    background-color: #003a70;
    border-color: #003a70;
}

.slider-title {
    font-size: 3.5rem!important;
    line-height: 4.5rem;
    font-weight: 400;

	@include media-breakpoint-down(sm){
		font-size: 2rem!important;
		line-height: 2rem;
	}
}

.image-caption {
	top: 10%;
	left: 50%;

	

	.img-front {
		position: absolute;
		top: 60%;
		left: 40%;

		@include media-breakpoint-down(md){
		top: 350px;
		left: 3%;
		}
	}

	@include media-breakpoint-down(md){
		top: 19%;
		left: 60%;

		.int-banner {
			width: 70%;
		}
	}

}

@include media-breakpoint-down(md){
	
	.carousel-banner-cap {
		top: 18%;
	}
}

@include media-breakpoint-down(sm){
	
	.carousel-banner-cap{
		top: 10%;
		text-align: center;
	}
}

//*** HP COMPONENTS STYLE END ***//

.title-404 {
	font-size: 3.5rem;
    line-height: 4.188rem;
    font-weight: 300;
    color: #ffffff;
}


.paypal-payment-container{
	border-top-left-radius: 12px;
    	border-top-right-radius: 12px;
	background: #fff; //f7f8f9
   	border: 1px solid #e6e9eb;	
}

.paypal-payment-container--selected{
	border-top-left-radius: 12px;
    	border-top-right-radius: 12px;
	background: #fff; //f7f8f9
    	border: 1px solid #e6e9eb;	
	border-radius: 12px;

}
/*
.sepa-bank-transfer-payment-container{
	border-radius: 12px;
	background: #fff;
   	border: 1px solid #e6e9eb;
	margin-top:8px;
}
*/

.sepa-bank-transfer-payment-container--selected{
	border-top-left-radius: 12px;
    border-top-right-radius: 12px;
	background: #fff; //f7f8f9
    border: 1px solid #e6e9eb;	
	border-radius: 12px;

}

#dropin-container{
	.adyen-checkout__payment-method--bankTransfer_IBAN{
		height: 0px !important;
		display: none;
	}
	.adyen-checkout__payment-method--bankTransfer_IBAN.adyen-checkout__payment-method--next-selected{
		display:none !important;
		height: 0px !important;
	}
	.adyen-checkout__payment-method{
		border:0;
	}
	.adyen-checkout__button{
		@extend .btn;
		@extend .btn-orange;
	}
}
.sepa-bank-transfer-payment-container{
	.adyen-checkout__button.adyen-checkout__button--standalone.adyen-checkout__button--pay{
		@extend .btn;
		@extend .btn-orange;
	}
}

.content-striped.order-vertical{
	padding-left:0!important;
	padding-right:0!important;
}

#CybotCookiebotDialogBodyButtonAccept{
	background-color: #0988ca!important;
    border: 1px solid #0988ca!important;
}

#CybotCookiebotDialogBodyContentTitle, #CybotCookiebotDialog{
	font-family: $font-family-sans-serif;
	color:$primary!important;
}


.margin-top-120{
	margin-top: 120px;
}

.btn-pwd{
	border-top-right-radius: $border-radius-sm !important;
    border-bottom-right-radius: $border-radius-sm !important;
	border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

#login_password,#registeruser_pwd,#registeruser_pwd_confirm{
	border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.round-border{
	border-radius: 2.5rem;
}

.quantity-input,#promo_code{
	border:none;
}

.img-custom-wishlist{
	position: relative;
    top: auto;
    left: auto;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    transition: opacity .4s ease-out;
}  

.hr{
	border-bottom: 1px solid #dedede;
}

.wishlist-section, .order-section{
	margin-top: 120px;
	@include media-breakpoint-down(xs){
		margin-top: 90px;
	}
}

.font-11{
	font-size: 11px;
}

.modal-quickbuy{
	.modal-content{
		border-radius: 2.5rem;
		#quickbuy_content{
			img{
				width: 200px;
			}
			.product-details{
				header{
					h1{
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}

#send_wishlist_form{
	.modal-content{
		border-radius: 2.5rem;
	}
	#message{
		font-size: 12px;
	}
}

#refund-change{
	.card{
		border: none;
		border-radius: 0 !important;
	}
	.card-refund{
		border-bottom: 1px solid #dedede !important;
	}
}

.font-12{
	font-size: 0.75rem;
}

#return-area-content{
	.card-footer{
		border-bottom-left-radius:2.5rem;
		border-bottom-right-radius:2.5rem;
	}
}

.card-order{
	.card-header{
		border-top-left-radius: 2.5rem;
		border-bottom-left-radius: 2.5rem;
		@include media-breakpoint-down(xs){			
    		border-radius: 2.5rem !important;
		}
	}
	.card-body{
		border-top-right-radius: 2.5rem;
		border-bottom-right-radius: 2.5rem;
		@include media-breakpoint-down(xs){
			border-radius: 2.5rem!important;
		}
	}    
}

.round-grey-bg{
	background-color: #fafafa;
	border-radius: 2.5rem;
}


.landing-page{
	.subtitle{
		color: #007BC2;
		font-weight: bold;
		font-size: 18px;
		line-height: 28px;
	}
	.title{
		color: #007bc2;
		font-weight: bold;
		font-size: 36px;
		line-height: 46px;
		width: 95%;
		padding: 20px 0px;
	}
	.description{
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: #0C4365;
	}
	.col-info{
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center
	}
	@include media-breakpoint-down(xs){	
		//flex-direction: column-reverse;
		.title{
			font-size: 28px;
			padding: 10px 0px;
		}
		.col-image{
			display: flex;
    		justify-content: center;
			img{
				width: 100%;
			}
		}
	}
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
			.col-image{
			display: flex;
    		justify-content: center;
			}
	}
}

.component-img-text{
	border-radius: 24px;
	margin-top: 36px;
	margin-bottom: 20px;
	padding: 40px;
	justify-content: space-between;
	.title{
		font-weight: bold;
		font-size: 40px;
		line-height: 48px;
		color: #0C4365;
	}
	.description{
		font-weight: lighter;
		font-size: 16px;
		line-height: 28px;
		color: #0C4365;
	}
	.col-image{
		display: flex;
    	justify-content: center;
		img{
			border-radius: 24px;
		}
	}
	.col-info{
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		padding-top: 40px;	
	}
	@include media-breakpoint-down(xs){	
		flex-direction: column-reverse;
		padding: 16px;
		.title{
			font-size: 28px;
		}
		.col-image{
		display: flex;
    	justify-content: center;
		padding: 0;
		img{
			width: 100%;
			}
		}
		.col-info{
			align-items: center;
			justify-content: center;
			padding-top: 30px;
			img{
				width: 72px;
				height: 64px;
			}
		}
		a.btn{
			width: 100%;
		}
	}
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
		.col-info{
			align-items: center;
    		justify-content: center;
		}
	}
}

.component-three-columns{
	border-radius: 24px;
	.title{
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		text-align: center;
		color: #007BC2;
	}
	div{
		padding: 3.5rem;
		img{
			height: 70px;
		}
	}
	@include media-breakpoint-down(xs){	
		flex-direction: column;
		div{
			padding: 1rem;
			img{
				height: 70px;
			}
		}
		div:first-child {
			padding-top: 50px;
		}
	}
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
		>div{
		padding: 1.5rem;
		}
	}
}


.collapse-help.collapsing{
	/*transition-property: height;
    transition-duration: 2s;
    transition-timing-function: linear !important;
   transition-delay: .2s;*/
   transition: none !important;
}
.collapse-help{
	transition-property: height;
    transition-duration: 2s;
    transition-timing-function: linear !important;
   transition-delay: .2s;
}


.mobile-help-height{
	height: 100vh;
	overflow-y: auto;
}



// START CONFIGURATOR
#configuratorModal{
	padding-right: 0px !important;
	.modal-dialog{
		margin-right: 0px;
		@include media-breakpoint-down(sm){	
			margin: 0px;
    		height: 100vh !important;
			align-items: flex-start !important;
		}
	}
	.modal-header .close{
		color:white;
		background-color: $light-blue;
		opacity: 1;
		padding: 1.375rem;
	}
	.modal-content{
		background-color: $orange;
		border-radius: 1rem 0 0 1rem;
		.modal-body{
			min-height: 400px;
			@include media-breakpoint-down(sm){	
				min-height: 100vh;
			}
		}
		@include media-breakpoint-down(sm){	
			border-radius: 0;
		}
		border: 0px;
		.answers-table{
			ul{
				list-style: none;
			}
			li{
				a{
					display:block;
					width: 184px;
					height: 184px;
					margin:0.75rem;
					text-align: center;
					background-color: $light-blue;
					color: white;
					border: 2px solid white;
					border-radius: 1.5rem;
					letter-spacing: 0.05em;
					font-weight: 700;
					position:relative;

					@include media-breakpoint-down(sm){	
						&.description-text{
							margin-bottom: 6rem;
						}
					}

					.description{
						position:absolute;
						top:100%;
						left: 0px;
						padding-top:1rem;
						font-size:0.875rem;
						font-weight: 500;
						color: $blue;
					}
					.text_icon{
						font-size: 2.5rem;
						font-weight: bold;
					}
					&:hover{
						text-decoration:none;
						color:$orange;
					}
				}

			}
		}
	}
	
}
// END CONFIGURATOR

.page_confirm, .page_cart_checkout, .page_cart, .page_orderview, .page_login,
.page_wishlist {
	background-color: #f8f8f8;
}

#accordionCart {
	.card .card-header, .card-body {
		background-color: #f8f8f8;
	}
}

.page_wishlist #breadcrumb-container{
	background-color: #9991b9;
}

.category-list{
		@include media-breakpoint-down(md) {
			margin-left: -5px;
			margin-right: -5px;
  		}
	}

#wishlist-container {

	@include media-breakpoint-up(sm){
		padding: 10px;
	}

	.category-list{
		display: flex;
		
	}

	.wishlist-products{
		padding-bottom: 18px;
		padding: 5px;
		.images-container{
			aspect-ratio: 4/3;
			display: block;
			overflow: hidden;
		}
		
		.card{
			height: 100%;
			.card-body{
				padding: 1rem;
			}

			font-family: $font-family-ingra-wide;
			border-radius: 1.25rem;
			box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 10%);
			&:hover{
				box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 20%);
			}

			background-color: white;
			@include media-breakpoint-down(sm){
				margin: auto;
			}

			.product-content{
				.images-container{
					img{
						border-top-right-radius: 10px;
						border-top-left-radius: 10px;
					}
				}
			}

			.product-options.card-body{
				.card-title{
					color: $black;
					font-family: $font-family-ingra-wide;
					font-weight: 600;
					//font-size: 1.438rem;
					@include media-breakpoint-down(md) {
						font-size: 1rem;
    					letter-spacing: -.02em;
					}
				}
				.card-text{
					color: $black;
					font-family: $font-family-ingra-wide;
					font-weight: 400;
					//font-size: 1.25rem;
					min-height: 40px;
					@include media-breakpoint-down(md) {
						color: #5f6973;
						font-weight: 400;
						font-size: .75rem;
					}
				}
				.price{
					display:block;
					font-weight: bold;
					
					//font-size: 2.188rem;
					>span{
						font-family: $font-family-ingra-wide;
						color:$black;
						height: 28px;
						font-weight: 600;
						font-size: 23px;
						line-height: 32px;
						@include media-breakpoint-down(md) {
							font-size: 1rem;
						}
					}
					>del{
						font-family: $font-family-ingra-wide;
						color:$black;
						height: 28px;
						font-weight: 600;
						font-size: 1.5rem;
						line-height: 32px;
					}
				}
				

				
			}

			.btn{
					width: 100%;
					font-weight: 400;
					font-size: 0.875rem;
					//line-height: 19px;
					text-align: center;
					text-transform: uppercase;
					padding: 1rem 1rem;
					@include media-breakpoint-down(md) {
						padding: 0.5rem;
					}
				}


		}
	}	

}

.btn-remove {
	color: #ACA8C5;
	background-color: transparent;
	border-color: #ACA8C5
}

.btn-remove:hover {
	color: #ffffff;
	background-color: #ACA8C5;
	border-color: #ACA8C5
}

.blue-bg{
    background-color: $blue;
}

#accordion-help-area{
	padding-top: 0px;
	@include media-breakpoint-down(sm) {
		padding-top: 50px;
	}
	.infoCnt{
		#accordion-help{
			
			@include media-breakpoint-down(sm) {
				padding: 0px;
			}
			.card{
				border-bottom: 1px solid #dee2e6 !important;
				.card-header{
					background-color: $white !important;
					padding: 25px 1.25rem;
					@include media-breakpoint-down(sm) {
						padding: 25px 1.25rem;
					}
					a{
						
						//line-height: 120px;
						
						text-transform: none;
						@include media-breakpoint-down(sm) {
							font-size: 20px;
						}
						&::after{
							transform: scale(1.5) rotate(45deg);
							top: 28px;
							right: 32px;
							transition: all 0.2s ease-in-out;
							
							@include media-breakpoint-down(sm) {		
								transform: scale(1) rotate(45deg);
								top: 32px;
								right: 22px;
							}
						}
						&.collapsed::after{
							transform: scale(1.5);
							top: 28px;
							right: 32px;
							transition: all 0.2s ease-in-out;
							
							//content: url("../../images/icons/accordion-close.svg");
							@include media-breakpoint-down(sm) {
									transform: scale(1);
									top: 32px;
									right: 22px;
								}
							}
						&:hover{
							text-decoration: none;
						}
					}
				}
				.card-body{
					font-weight: 400;
					font-size: 16px;
					line-height: 25px;
					background-color: white;
					h3{
						font-weight: 600;
						font-size: 23px;
						line-height: 32px;
						padding-bottom: 30px;
    					padding-top: 15px;
					}
					p{
						font-weight: 400;
						font-size: 16px;
						line-height: 25px;
					}
					img{
						width: 460px;
						height: 300px;
						margin: auto;
						display: flex;
					}
				}
				&:first-of-type{
					border-top: 1px solid #dee2e6!important;
				}
				div.show{
					border-bottom: 1px solid #dee2e6;
				}
			} 
		}
	}
	#accordion-help-area-inside{
		.accordion{
			.card{
				border-bottom: none !important;
				.card-header{
					padding: 25px;
					@include media-breakpoint-down(sm) {
						padding: 15px;
						height: 60px;
					}
					a{
						&::after{
							transform: scale(.3);
							top: 15px;
							right: 10px;
							@include media-breakpoint-down(sm) {	
								transform: scale(.2);
								top: 0px;
								right: 15px;
							}
						}
						&.collapsed::after{
							transform: scale(1);
							top: 10px;
							right: 12px;
							//content: url("../../images/icons/accordion-close.svg");
							@include media-breakpoint-down(sm) {
								transform: scale(.7);
								top: 9px;
								right: 0px;
							}
						}
						h3{
							padding: 0;
							font-weight: 300;
							font-size: 26px;
							margin-bottom: 0px;
							@include media-breakpoint-down(sm) {
								font-size: 16px;
							}
						}
						@include media-breakpoint-down(sm) {
							padding: 0px;
						}
					}
				}
				.card-body{
					background-color: $white;
					p{
						font-weight: 300;
						font-size: 14px;
						line-height: 25px;
					}
				}
			}
		}
	}
}

#accordion-help-area{
	.help-title.collapsed::after {
		content: url(../../images/icons/iconmonstr-plus-thin.svg);
	}
	.help-title::after {
		content: url(../../images/icons/iconmonstr-plus-thin.svg);
	}
}
#carouselInfoDesk{
	display: flex;
		@media (min-width: 320px) and (max-width: 1180px) {
			display: none;
		}
}

#carouselInfo {
	display: none;
		@media (min-width: 320px) and (max-width: 1180px) {
			display: block;
			margin: auto -15px;
		}
	.carousel-indicators {
		right: 50%!important;
		bottom: 7px!important;
	}
}

.dealersuggested{
	overflow: hidden;
	h4 {
		padding: 40px 0 40px 0;
		margin: 0;
		font-size: 35px;
		line-height: 50px;
		font-weight: 600;
		//color: #003A70;
	}
	p.text-center{
		color:#7B7B7B;
	}
	
	.category-products{
		margin-bottom: 30px;
	}
	.tns-outer{
		width: 120%;
	}
	.control li{
		cursor: pointer;
	}
	.control .next{
		transform: rotate(180deg);
		margin-left: 20px;
	}
	.tns-nav{
		text-align: center;
		width: 83%;
		button{
			width: 363px;
			height: 7.98px;
			background: #FCAF17;
			border-radius: 0px;
			border: 0;
			padding: 0;
			margin: 0;
			&.tns-nav-active{
				background: #2D2D70;
			}
		}
	}
}


.dealer-col{
	background-color: #f8f8f8;
	@include media-breakpoint-up(sm){
		padding: 60px 30px;
	}

	.section-ht{
		.ht-info{
		display: flex;
		flex-wrap: wrap;
			p{
				padding: 0px;
				margin-right: 15px;
				margin-bottom: 10px;
				font-size: 0.75rem;
				display: inline-block;
				color: #5f6973;
			}
		}
		@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
			display: none;
		}
	}


	.category-products{
		padding: 10px 15px;
		display: flex;
		justify-content: center;
		@include media-breakpoint-down(sm) {
			padding: 18px;
		}

		.card{
			height: 100%;
			.card-body{
				padding: 1rem;
			}
			font-family: $font-family-ingra-wide;
			border-radius: 1.25rem;
			box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 10%);
			&:hover{
				box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 20%);
			}
			background-color: white;
			@include media-breakpoint-down(sm){
				margin: auto;
			}
			@media only screen 
				and (min-device-width: 768px) 
				and (max-device-width: 1024px) 
				and (orientation: portrait) 
				and (-webkit-min-device-pixel-ratio: 1) {
    				width: 300px;
			}
			.product-content{
				.images-container{
					img{
						border-top-right-radius: 1.25rem;
						border-top-left-radius: 1.25rem;
					}
				}
			}

			.product-options.card-body{
				> .row{
					height: 100%;
				}
				.card-title{
					color: $black;
					font-family: $font-family-ingra-wide;
					font-weight: 600;
					font-size: 1.2rem;
					//line-height: 32px;
				}
				.card-text{
					color: #5f6973;
					font-family: $font-family-ingra-wide;
					font-weight: 400;
					font-size: .75rem;
					//line-height: 32px;
					//min-height: 40px;
				}
				.price{
					display:block;
					font-weight: bold;
					//float:left;
					font-size: 1rem;
					//padding-left:8px;
					//min-height: 50px;
					//margin: 0px 0px 16px 0px;
					line-height: 100%;
					>span{
						font-family: $font-family-ingra-wide;
						color:$black;
						height: 28px;
						font-weight: 600;
						font-size: 23px;
						line-height: 32px;
					}
					del{
						font-family: $font-family-ingra-wide;
						font-size: 1rem;
						font-weight: normal;
						opacity: 0.5;
					}
				}
				.btn.btn-primary{
					width: 100%;
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 19px;
					text-align: center;
					//letter-spacing: 0.15em;
					text-transform: uppercase;
				}
			}
		}
	}	
}

.price-dealer {
	>span{
		font-family: $font-family-ingra-wide;
		font-size: 35px!important;
		font-weight: 600!important;
		line-height: 50px;
		letter-spacing: 0em;
		text-align: left;
	}
}

.product-counter {
	font-family: $font-family-ingra-wide;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0.15em;
	@include media-breakpoint-down(sm) {
		text-align: center;
		padding: 30px;
	}
}

.dropdown-mob {
	@include media-breakpoint-up(sm) {
		align-items: baseline!important;
		justify-content: flex-end!important;
	}

	@include media-breakpoint-down(sm) {
		.dropdown-toggle {
			width: 348px!important;
		}
		.dropdown-menu {
			width: 346px!important;
		}
	}
}

.page_cms {
	background-color: #f8f8f8;
}

#navbarNavMobile .text-white svg{
	fill: #fff;
	position: relative;
	top:-5px;
}
.title-container {
	.title-cart {
		@include media-breakpoint-up(md) {
			font-size: 3rem;
			margin-bottom: 2.6rem;
		}
	}
}

.padding-cart {
	padding-top: 30px;
	padding-bottom: 0px;
	@include media-breakpoint-down(sm) {
		padding-top: 0px;
	}
}

.product-line {
	//border-bottom: 1.5px solid rgba(0,0,0,.09);
}

.custom-title {
	font-size: 11px;
}

.btn-promo{
	padding: 0 4px;border-radius: 0;font-size:12px;
}

.total-custom{
	font-size:14px;
	font-family: suiss;
	.btn-promo {
	z-index: 2;
    margin-left: 1px;
    margin-right: 14px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 0;
    height: 44px;
		background-color: #000;
		color: #fff;
		border-bottom-right-radius: .375rem;
    border-top-right-radius: .375rem;
	}
	.total-desc {
		font-size:16px;
	}
}


#accordition-product,
#cart-help,#accordion-hiw,#accordion-help-area,#accordion-help-area-inside{
	.card {
		border:none!important;
		.card-header{
			background-color: #fff;
		}
	}
	.collapse-toggle, .collapse-toggle-upwards {
		&.collapsed:after {
			content: fa-content($fa-var-plus);
		}
	}
}

.product-custom {
 	font-size: 0.875rem;
 	line-height: 1.063rem;
	font-weight: 700;
}

.small-desc {
 	font-size: 0.7rem;
	font-family: suiss;
} 

.divider {
	margin-bottom: -1rem;
}

.price-custom {
	font-size: 0.875rem;
 	line-height: 1.063rem;
	 font-weight: 700;
}

.custom-size {
	border: 1px solid #000;
	padding: 3px;
}
.qty-custom{
	.form-custom, .btn-outline-secondary{
		height:36px;
	}

	.form-custom{
		border: none;
		font-size: 0.875rem;
		line-height: 1.063rem;
		font-weight: 700;
	}

	button.btn{
		padding:1px 6px;
	}
/*
	@include media-breakpoint-down(sm){
		.cart-qty-selector{
			background-color: #f2f2f2;
		} 
		
	}*/
}

.card-address {
	border:none!important;
}

.img-custom{
	width:150px;
}

.remove-custom {
	background-color: transparent;
}

/*.opt-custom {
	width: 50%;
	padding-left: 30px;
}*/

/*.wish-custom{
	width:100%;
}*/

.product-sheet, .remove {
	font-size:12px;
}

.wish-remove {
 width:100%;
}

.wish-title {
	//font-size:11px;
	display: block;
    text-align: left;
    color: #0c4365;
    cursor: pointer;
	height: 50px;
	a{
		min-height: 44px;
		font-size: 18px;
		font-weight: 700;
		color: #0c4365;
		margin-bottom: 12px;
	}

}

.product-options-wishlist{
	.options-prod{
		line-height: 16px;
		letter-spacing: -.02em;
		padding-bottom: 14px;
		border-bottom: solid 1px #dedede;
		font-weight: 700;
		font-size: 14px;
		margin-bottom: 14px;
		padding-top: 14px;
		width: 100%;
	}
	.price-wishlist{
		display: flex;
		.wish-custom{
			padding-left: 10px;
			.to-sell{
				font-weight: 700;
			}
		}
	}

}

.text-price{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
}


		.description-wishlist{
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
			}

.brand-egosan{
			background: #0c4365;
			display: inline-block;
			font-weight: 500;
			font-size: 10px;
			line-height: 15px;
			letter-spacing: -.02em;
			text-transform: capitalize;
			text-align: center;
			padding: 3px 8px;
			color: #fff;
			border-radius: 16px;
		}

.wishlist-title,.order-title{
	font-weight: bold;
	font-size: 28px;
	line-height: 40px;
	color: #0C4365;
}

.custom-checkbox{
	margin: 0;
	position: relative;
	display: block;
}


.radio{
	position: absolute;
	margin-left: -25px;
	margin-top: 1px;
}

.payment-custom {
 .navlink &.active{
	background-color: transparent!important;
 }
}

.products-list-header {
	max-height: calc(100vh - 350px);
  	overflow-y: auto;
	  overflow-x: hidden;
}

.total-custom {
	font-size: 1.5rem;
}

.custom-inst {
	font-size:0.688rem;
}

.high-custom {
	.register-tab, .login-tab, .guest-tab {
		h3 {
			color: #2980C4;
		}
	}	
}

.header-order {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.02em;
	color:#fff;
	padding:8px 0;
	background-color: $blue;
	font-family:  $font-family-sans-serif;
	text-align: center;
}

.header-summary {
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 700;
}


 .custom-detail {
	font-weight: 700;
}

.delivery-tag {
	font-size: 0.75rem;
	line-height: 0.938rem;
	color: #9A9A9A;
}

.opt-name {
	font-size: 0,75rem;
	line-height: 0.938rem;
	color: #959595;
}

.title-confirm {
	font-size: 1rem;
	line-height: 1.189rem;
	font-weight: 700;
}

.card-info {
	font-size: 0.75rem;
	line-height: 1.438rem;
}

.red-tag {
	color: #D82333;
}

@include media-breakpoint-up(md) {
	.padding-delivery {
		padding-right: 15%;
	}
}

.product-confirm {
	font-size: 0.75rem;
    line-height: 0.938rem;
    font-weight: 700;
}

.btn-edit-address {
	position: absolute;
	z-index: 9;
	right: 0px;
}

@include media-breakpoint-down(lg) {
	.button-minw-mobile{
		min-width: 340px;
	}
}


.select-css {
  position: relative;
  z-index: 10;
  background-color: transparent !important;
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }
}
.custom-select-icons {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 10;
  border: 1px solid white;
  background: transparent;
  cursor: pointer;
}
.custom-select-options {
  border: 1px solid #aaa;
  border-radius: 0 0 0.25em 0.25em;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
  cursor: pointer;
  z-index: 3;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  color: $primary;
  li {
    padding: 1em;
    border: 1px solid white;
    &.selected {
      background-color: #fff;
      border-color: $primary;
      color: $primary;
    }
    &:hover {
      background-color: #fff;
      border-color: $primary;
      color: $primary;
    }
  }

}
.hidden-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.help-title {
	font-weight: 400;
    font-size: 1.875rem;
    line-height: 54px;
    text-transform: capitalize;
	@include media-breakpoint-down(sm) {
		font-size: 15px;
	}
	&::first-letter {
		text-transform: uppercase;
	}
	&::after{
		position: absolute;
		top: 19px;
		right: 7px;
		content: url("../../images/icons/close-x.svg");
		@include media-breakpoint-down(sm) {
			top: 17px;
			right: 8px;
		}
		@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) 
		and (orientation: portrait) 
		and (-webkit-min-device-pixel-ratio: 1) {
				top: 28px;
				right: 12px;
		}
	}
	&.collapsed::after{
		position: absolute;
		top: 15px;
		right: 12px;
		content: url("../../images/icons/iconmonstr-plus-thin.svg");
		@include media-breakpoint-down(sm) {
			top: 17px;
			right: 8px;
		}
		@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) 
		and (orientation: portrait) 
		and (-webkit-min-device-pixel-ratio: 1) {
				top: 28px;
				right: 12px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 10px;
		text-transform: uppercase;
	}
}


#breadcrumb-container {
	.continue-shop {
		font-size: 1rem;
		line-height: 19.2px;
		font-weight: 500;
		text-transform: uppercase;
	}
}

.custom-info {
	padding-top: 15px;
	font-size: 1.3rem;
	line-height: 32px;
	font-weight: 600;
	line-height: 100%;
	del{
		font-size: 1rem;
		font-weight: 400;
		opacity: .5;
	}
	label.head{
		font-size: 1rem;
		font-weight: 400;
	}
}

.product-description {
	font-size: 1.438rem;
	line-height: 32px;
	font-weight: 400;
}

.button-proceed {
	text-align: right;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.cust-name {
	font-size: 1rem;
	color: $white;
    line-height: 32px;
    font-weight: 400;
	&:hover{
		text-decoration: none;
		color: $orange;
	}
}

.custom-price {
	font-size: 1.438rem;
	color: $white;
    line-height: 32px;
    font-weight: 400;
}



.page_register{
	//background-color: $blue;

	.register-info {
		margin-top: 200px;
	}

	.title-select {
		//color: #ffffff;
		

	}

	.placeholder-email-image {
		//padding: 2rem 2.188rem;
		padding-left: 50px;
		background-image: url("../../images/custom/envelop.png");
		background-repeat: no-repeat;
		background-size: 30px 21px;
		background-position: 12px center;
		filter: grayscale(100%); /* Current draft standard */
		-webkit-filter: grayscale(100%); /* New WebKit */
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%); 
		-o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */ 
		filter: url(../../images/custom/envelop.png#desaturate); /* Gecko */
		filter: gray; /* IE */
		-webkit-filter: grayscale(1); /* Old WebKit */
	}

	.placeholder-password-image {
		//padding: 2rem 2.188rem;
		padding-left: 50px;
		background-image: url("../../images/custom/candado.png");
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-position: 12px center;
		filter: grayscale(100%); /* Current draft standard */
		-webkit-filter: grayscale(100%); /* New WebKit */
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%); 
		-o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */ 
		filter: url(../../images/custom/candado.png#desaturate); /* Gecko */
		filter: gray; /* IE */
		-webkit-filter: grayscale(1); /* Old WebKit */
	}

	.placeholder-user-image {
		//padding: 2rem 2.188rem;
		padding-left: 50px;
		background-image: url("../../images/custom/user.png");
		background-repeat: no-repeat;
		background-size: 30px 30px;
		background-position: 12px center;
	}

	.placeholder-user {
		//padding: 2rem 2.188rem;
		padding-left: 50px;
	}
}

.content-container {
	display: grid;
    align-content: center;
    height: 100vh;
    background-color: rgba(255,255,255,0.5);
	@include media-breakpoint-down(sm) {
		height: 35vh;
	}
}

.content-main-container {
	//display: grid;
    //align-content: center;
    //height: 100vh;
}

.close-log {
	position: absolute;
    right: 30px;
    top: 30px;
}

.payment_methods {
	.btn-info-payment {
		color: #d5d4e2;
		background-color: transparent;
		border-color: #d5d4e2;
	}
}




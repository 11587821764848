.container-search{
  background-color: #fdcf74;
}
[data-zg-role="product-finder-elastic"] {
  display: flex;
  width: 100%;
  @include media-breakpoint-down(md) {
    border: 1px solid transparent;
  }
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    @include media-breakpoint-down(sm) {
      //margin-left: 14px;
      //width: 355px;
      height: 50px;
      width: auto;
    }
  }
}

[data-zg-role="btn-elastic-search"] {
  border: 1px solid $gray-400;
}

#searchElasticContainer {
  > .input {
    flex-wrap: nowrap;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    .input-group {
       // background-color: white;
    }
  }
}
[data-zg-role="search-elastic-input"] {
  width: 16vw;
  max-width: 700px;
  border-radius: 8px!important;
  border: 1px solid rgba(0,0,0,.125);
  height: 50px;
  //padding: 26px;
  background-color: white;
  color: #5f6973;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  &:focus {
    //border-color: $gray-400;
    background-color: white;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: none;
  }
  .input-group {
    z-index: 3;
  }

  

  @include media-breakpoint-down(md){
    
  }
 
}

.search-btn {
  position: absolute;
  right: 150px;
  top: 15px;
  z-index: 3;
}

.search-btn-left {
  //position: absolute;
  //right: 590px;
  margin-right: -50px;
  margin-left:20px;
  z-index: 98;
  .btn {
     &:hover {
       background-color: transparent!important;
     }
  }
  @include media-breakpoint-down(sm) {
    //right: 50%;
    //margin-right: -38px;
    //margin-top:-8px;
    // z-index: 0;
  }
}

#elastic_mobile_search_button {
  @include media-breakpoint-down(md) {
    width: 93%;
    background: #fcaf17;
    color: white;
    //border-radius: 0 0 4px 4px;
    padding: 15px 0;
    height: auto;
    margin-bottom: 10px;
    margin-left: 10px !important;
  }
}

#typeahead-search-button {
  &:hover,&.selected  {
    //background: $gray-200;
  }
  span {
    display: none;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  i {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

button[data-zg-role="btn-close-elastic-search"] {
  font-size: 38px;
  color: $gray-500;
  z-index: 4;
  height: 50px;
  width: 50px;
  padding: 0 10px 0 32px;
  &:focus {
    box-shadow: none;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.erase-btn {
  position: absolute!important;
  left: -117%;
  top: 14%;
  color: $gray-500;
  background: transparent;
  @include media-breakpoint-down(md) {
    position: relative !important;
    font-size: 20px;
    right: -44px;
    left: auto;
    top: 0px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }
   @include media-breakpoint-down(sm) {
    position: relative !important;
    font-size: 20px;
    right: -62px;
    left: auto;
    top: 6px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }
}

.opacity-search {
  width: 100%;
  height: calc(100vh + 100px);
  opacity: 0;
  background: $gray-600;
  position: fixed;
  z-index: 2;
  display: block;
  top: -100px;
  left: 0;
  @include media-breakpoint-down(md) {
    background: white;
    opacity: 1;
  }
  @include media-breakpoint-down(sm) {
    top:auto;
  }
}

ul[data-zg-role="typeahead-ul-elastic"] {
  padding: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 9;
  position: absolute;
  height: auto;
  width: 100%;
  left: 0px;
  top: 71px;
  box-sizing: content-box;
  background-color: white;
  @include media-breakpoint-down(lg){
    //left: -570px;
  }
  @include media-breakpoint-down(md){
    //width: calc(100% - 30px);
    left: 0;
    //padding: 0 15px;
  }
  .loading {
    position: absolute;
    min-height: 100px;
  }
}

.row-custom-width {
  width: 73.8%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.result-product-container {
  position: static;
  &.line-bottom {
    border-bottom: 1px solid $gray-400;
  }
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    &.line-bottom:last-of-type {
      border-bottom: none;
    }
    &:first-of-type {
      .elastic-item-link {
        order: 2;
      }
    }
    &.line-bottom-mobile{
      border-bottom: 1px solid $gray-400;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  &:hover,&.selected {
    text-decoration: none;
  }
}

.elastic-item-container {
  padding: 10px;
  height: 100%;
  .price{
    display: none;
  }

  @include media-breakpoint-down(md) {
    padding: 11px 16px !important;
  }
  &:hover,&.selected {
    background-color: $gray-200;
  }
}

.elastic-img-container {
  padding: 0;
  img {
    @include media-breakpoint-down(md) {
      max-height: 50px;
    }
  }
}

.elastic-item-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  @include media-breakpoint-down(md) {
    height: auto;
    font-size: 1.2em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }
  &:hover,&.selected {
    text-decoration: none;
  }
}

.elastic-item-text-box {
  display: flex;
  flex-direction: column;
  height: 80px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 75%;
  max-width: 75%;
  @media (min-width: 420px) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

[data-zg-role="elastic-data-container"] {
  @include media-breakpoint-down(md) {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 0;
      order: 1;
      width: auto;
      text-align: left;
      max-width: 85%;
      font-size: 12px;
    }
    .price{
      background-color: $gray-400;
      border-radius: 4px;
      padding: 2px 4px;
      margin-left: auto;
      position: static;
      order: 2;
      width: auto;
      font-size: 13px;
    }
  }
  @include media-breakpoint-down(sm) {

    p {
      max-width: 75%;
    }
    .price{
      max-width: 25%;
      max-height: 22px;
    }
  }
}

.elastic-item-price {
  background-color:  $gray-400;
  border-radius: 4px;
  padding: 2px 4px;
  height: 28px;
  position: absolute;
  top: 9px;
  right: 16px;
  @include media-breakpoint-down(md) {
    position: static;
    order: 2;
    width: auto;
    max-width: 20%;
    margin-left: auto;
    padding: 2px 4px;
    font-size: 13px;
    max-height: 22px;
  }
}

.elastic-item-desc {
  font-size: 0.8rem;
  color:  $gray-600;

  @include media-breakpoint-down(md) {
    order: 3;
    width: 100%;
    text-align: left;
  }
}

.elastic-img {
  max-width: 62px;
  height: auto;
}

[data-zg-role="elastic-right-column"]{
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 14px;
  order: 0;
  @include media-breakpoint-up(lg) {
    width: 35.4%;
    position: absolute;
    top: 0;
    left: 100%;
    min-height: 100%;
    border-left: 1px solid $gray-400;
  }
  ul {
    padding: 0;
    li {
      font-weight: bold;
      list-style: none;
      padding: 11px 18px;
      position: relative;
      z-index: 10;
      width: 220px;
      cursor: pointer;
      text-align: left;
      em {
        font-weight: normal;
        font-style: normal;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        border-bottom: 1px solid $gray-200;
        font-size: 12px;
        &:nth-of-type(n+2) {
          border: none;
        }

        &:last-of-type {
          border: none;
        }
      }
      &:hover,&.selected {
        background-color: $gray-200;
      }
    }
  }
}

.heading-elastic{
  display: flex;
  font-weight: 400 !important;
  text-align: left;
  margin-bottom: 0;
  padding: 10px 0 0 0;
  height: auto;
  color: $gray-600;
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    padding: 9px 18px;
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto auto auto 0.4rem;
    }
  }
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid #999999;
  }
}

ul div.result-product-container{
  @include media-breakpoint-down(md) {
    &:nth-of-type(n+8) {
      border-bottom: none;
    }
    &:nth-of-type(n+7) {
      display: none;
    }
  }
}

ul div.result-product-container.products-only {
  @include media-breakpoint-down(sm) {
    &:nth-of-type(n+3) {
      border-bottom: 1px solid $gray-400;
    }
    &:nth-of-type(n+4) {
      display: flex;
    }
    &:nth-of-type(n+5) {
      border-bottom: none;
    }
    &:nth-of-type(n+6) {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    &:nth-of-type(n+5) {
      border-bottom: 1px solid $gray-400;
    }
    &:nth-of-type(n+6) {
      display: flex;
    }
    &:nth-of-type(n+8) {
      border-bottom: none;
    }
    &:nth-of-type(n+9) {
      display: none;
    }
  }
}

ul div.result-product-container:nth-of-type(n+6) {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

span[data-elastic="name"], div[data-elastic="desc"] {
  em {
    font-weight: bold;
    font-style: normal;
  }
}

.mobile-show-logo {
  @include media-breakpoint-down(md) {
    #searchElasticContainer {
      height: auto;
      padding: 0px;
      //background-color: $blue;
      z-index: 4;
    }

    [data-zg-role="btn-close-elastic-search"] {
      display: none !important;
    }

    ul[data-zg-role="typeahead-ul-elastic"] {
      padding: 0;
      margin: 0;
    }

    #elastic_mobile_search_button {
      //box-shadow: 0px 5px 5px 0px rgba(117,117,117,1);
    }

    [data-zg-role="elastic-right-column"] ul li {
      border-bottom: 1px solid $gray-200 !important;
    }

    ul div.result-product-container{
      @include media-breakpoint-down(md) {
        &:nth-of-type(n+4) {
          border-bottom: none;
        }
        &:nth-of-type(n+3) {
          display: none;
        }
      }
    }
    .opacity-search {
      background: $gray-600;
      opacity: 0;
    }
  }
}

.full-screen-search {
  @include media-breakpoint-up(lg) {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #f9f9f9;

  [data-zg-role="product-finder-elastic-form"] {
    background-color: white;
  }
  button[data-zg-role="btn-close-elastic-search"] {
    display: flex;
    color: black;
    .fa-chevron-circle-left:before {
      content: '\f104';
    }
  }
  #typeahead-search-button {
    border-radius: 0;
    margin-right: 28px;
    padding-left: 15px;
    color: #999999;
    border-left: 1px solid #999999;
    &:hover{
      background: none;
      color: black;
    }
  }
  .erase-btn {
    left: -75%;
    color: #999999;
    display: flex;
    align-items: center;
    height: 40px;
    &:hover{
      color: black;
    }
  }
  .input-group {
    align-items: center;
    border-bottom: 1px solid #bfbfbf;
  }
  .form-control {
    max-width: 100%;
    border-radius: 0;
    border:none;
    font-size: 24px;
    height: 75px;
    padding-left: 10px;
  }
  ul[data-zg-role="typeahead-ul-elastic"] {
    left: 0;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    background: none;
    margin-top: 1.75rem;
    height: calc(100vh - 70px);
  }
  [data-zg-role="elastic-row"]{
    margin-left: 25%;
    margin-right: 30px;
    max-width: 1040px;
    &.no-right-column {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .related-product-heading {
    height: 30px;
    margin-top: 12px;
    font-size: 1.15em;
    color: #999999;
    padding-left: 15px;
    padding-right: 15px;
  }

  .result-product-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .elastic-item-container{
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    border-radius: 12px;
    padding: 50px 20px;
    max-width: 300px;
    &.mobile {
      display: none;
    }
  }
  [data-zg-role="elastic-right-column"]{
    left: -34.4%;
    width: 32.4%;
    border-left: 0;
    border-right: 1px solid $gray-400;
    font-size: 1rem;
    padding: 0 30px;
    ul {
      margin-bottom: 2rem;
    }
  }

  [data-elastic="name"] {
    font-size: 22px;
  }
  .price {
    font-size: 16px;
  }

  .heading-elastic {
    &:after{
      border-bottom: none;
    }
  }
  .image-full-screen {
    max-height: 185px;
    width: auto;
  }
}
@include media-breakpoint-down(md) {
.elastic-item-container{
  &.desktop {
    display: none;
  }
  &.mobile {
    display: flex;
  }
}
}
}
.mw-85 {
max-width: 85%;
}

.input-elastic {
  width: 650px!important;
  @include media-breakpoint-down(md) {
    width: 534px!important;
  }
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}


.elastic-item-price{
  display: none !important;
}
#site_header{
	position:fixed;
	width: 100%;
	z-index:99;
	//background:linear-gradient(180deg, rgba(50, 35, 114, 0.6) 10.13%, rgba(50, 35, 114, 0) 100%);
	background: linear-gradient(180deg,rgba(21,23,48,.6) 10.13%,rgba(21,23,48,0) 100%);
	transition: background-color 0.5s ease;
	
	.border-top{
		border-top:1px solid $white;
	}

	@include media-breakpoint-up(lg) {
		.navbar-expand-lg{
			padding: 1.8rem 0 0 0;
			position: static;
		}
		.navbar-expand-lg .navbar-nav .dropdown-menu{
			z-index:99;
			.row{
				margin-left: calc(5% + 0.938rem);
    			margin-right: calc(5% + 0.938rem);
			}
		}
		
	}

	.dropdown-item{
		color: white;
		font-size: 1rem;
		line-height: 1.188rem;
		padding: $dropdown-item-padding-y 0;
		>a.h4{
			color: $white;
			line-height: 1.5rem;
    		margin-bottom: 0rem;
			font-size: 1rem;
		}
	}

	.dropdown-item.active, .dropdown-item:active{
		background-color:transparent;
		color:inherit;
		//text-decoration:underline;
		color: $orange;
		a{
			text-decoration:none;
			color: $orange;
		}
	}
	.dropdown-item:focus, .dropdown-item:hover{
		color: $primary;
    	background-color: transparent;
		a{
			text-decoration:none;
			color: $orange;
		}
	}

	.dropdown-toggle::after{display:none;}
	@include media-breakpoint-up(lg) {
		&.menuOpen{
			.dropdown-toggle:hover:after, .nav-link:hover:after, .nav-item.show .nav-link:after {
				opacity: 1;
			}
		}
	}

	.nav-link{
		@media (min-width: 768px) and (max-width: 1180px) {
			font-size: .8rem;
		}
		@include media-breakpoint-up(lg) {
			padding-bottom: 1.8rem;
		}
		&:hover,
		&.active{
			color:$primary;
			font-weight: 300;
		}
	}

	.active > a.nav-link{
		color:$primary;
		font-weight: 300;
	}


	.nav-btn{
		padding: 0.5rem!important;
	}

	.navbar-toggler {
		padding:0.438rem 1rem 0 0;
		p{
			margin-top:2px;
		}
	}

	.navbar-toggler-icon {
		//background-image: $navbar-home-toggler-icon-bg;
		display: inline-flex;
		flex-direction: row;
		width: auto;
		margin: auto;
		svg{
			fill:$white;
		}
	}

	.navitem-toggler{
		@extend .navbar-toggler;
		padding:.35rem .75rem;
		text-align: left;
		width: 100%;
		margin-top: 8px;
		margin-bottom: 15px;
	}
	.navitem-toggler-icon{
		@extend .navbar-toggler-icon;
		p{ margin-top:1px; }
	}


	&.header.menuOpen, &.header.header-regular{
		background-color:$blue !important;
	}
	&.header:hover{
		background-color:$blue !important;
	}


	@include media-breakpoint-down(md) {
		&.menuOpen{
			.navbar-brand{display:none;}
			.navbar-tool{position:absolute; top:0.5rem; right:0;}
		}
		&.cartOpen{
			background-color:$white;
			.nav-item .nav-link, .nav-item .nav-link svg{
				color: $gray-900 !important;
    		fill: $gray-900 !important;
			}
			svg{
				fill:$gray-900;
			}
			#dropdownCart .badge{
				border: 1px solid $gray-900;
				background-color: $gray-900;
				color: $white;
			}
		}
		&.menuOpen.searchOpen, &.cartOpen{
			.navbar-brand{
				display:block;
				>img.black {
    			display: block;
				}
				>img.white {
    			display: none;
				}
			}
		}
		
		.menu-icon, &.menuOpen.searchOpen .menu-icon, &.cartOpen .menu-icon{
			display:block;
			opacity:1;
		}
		.close-icon, &.menuOpen.searchOpen .close-icon, &.cartOpen .close-icon{
			display: none;
    		transition: opacity 1s ease-out;
    		opacity: 0;
		}
		&.menuOpen .menu-icon{
			display: none;
    		transition: opacity 1s ease-out;
    		opacity: 0;
		}
		&.menuOpen .close-icon{
			display:block;
			opacity:1;
		}
	}
}

#site_main{
	padding-top:87px;
	@include media-breakpoint-down(sm) {
		padding-top: 62px;
	}
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
		padding-top: 63px;
	}

	&.content_channel_camicioli,
	&.page_home,
	&.content_page_dealer{
		padding-top: 0px;
	}

}


.navbar-tool{
	.nav-link{
		padding: .25rem 0.625rem;
		@include media-breakpoint-down(sm) {
			padding: .25rem 0.25rem;
			svg{
				height: 22px;
			}
		}

	}
	.dropdown-menu{
		position: absolute;	
		padding: 1.875rem 1.375rem 1.875rem 1.375rem;	
		//top: 7.5rem;
		//width: 18rem;
		//right: 0;
		//left: 80%;
		//padding:1.875rem 1.375rem;
		/*
		@include media-breakpoint-up(lg) {
			left: calc(100vw - 18rem);
			}
		*/
	}


	.account-header {
		//right: 0px;
		min-width: 300px;
		color: $primary;
		font-weight: normal;
		a {
			i{
				margin-right: 10px;
				color: $primary;
			}
			display: block;
			padding: 15px 0;
			text-transform: uppercase;
			color: white;
		}
		@include media-breakpoint-down(sm) {
			top: 60px;
			left: 0px;
		}
	}


	.dropdown-cart{
		padding: 1.875rem 1.875rem 1.875rem 1.375rem;
		color: white;
		svg{
			top:2px;
		}
		.custom-price{
			font-size: 1rem;
		}
		.dropdown-item-text{
			color: white;
			padding: 0px;
		}
		.title-navbar{
			color: orange;
		}
		ul{
			//padding-right:1.375rem;
			&.products-list{
				//max-height: 50vh; // the client cannot see it properly with any size but 50vh. 
				//overflow-y:scroll;
				//overflow-x:hidden;
				//padding-right:0;
				@include media-breakpoint-down(lg) {
					max-height:50vh;
				}
				li.products-list-header{
					padding-right:0.75rem;
				}
			}
		}
	}
	#navbarCart{
		@include media-breakpoint-down(md) {
			.dropdown-menu{
				position: fixed;
				width:100vw;
				top: 60px;
				left: 0;
				border-left:none;
				border-right:none;
			}
		}
	}
	#navbarSearch{
		.dropdown-menu{
			position: fixed;
			width:100vw;
			top: 85px;
			left: 0;
			border-left:none;
			border-right:none;
			padding: 1rem 1.375rem;
			@include media-breakpoint-up(lg) {
				position:absolute;
				top:87px;
				left:0;
				width: 100%;
				padding: 1.875rem 1.375rem;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.navbar-brand{
		//margin-left:calc(5% + 0.938rem);
		margin-top: -30px;
	}
	.navbar-tool{
		margin-right:calc(5% + 0.938rem);
		.nav-link{
			color: white;
			font-size: 12px;
			label[data-zg-role="cart-items-class"]{
				position: relative;
				top: 4px;
			}
		}
		svg{
			position: relative;
			top: -2px;
		}

		/*
		> li.show > a::before{
			content: "";
			position: absolute;
			bottom: -19px;
			height: 3px;
			z-index: 10;
			background: $primary;
			width: 100%;
			left: 0;
		}
		> li#navbarSearch.show > a::before{
			bottom: -42px;
		}
		*/
		.nav-link{
			padding-left: 1.875rem;
			padding-right: 0;
			&:last-child{
				padding-right:0;
			}
		}
	}
}

#dropdownCart{
	.badge{
		position: absolute;
		top: 1.063rem; /*17px*/
		right: 0.313rem; /*5px*/
		height: 16px;
		width: 16px;
		line-height: 11px;
		font-size: 11px;
		padding: 2px;
	}
}

#notification_carousel{
	height:30px;
	padding:5px 0;
}

.navbar-dropdown-full{
	flex-grow: 1;
	align-items: center;
	display: flex!important;
	flex-basis: auto;
}

.dropdown-menu-full {
	width: 100% !important;
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 0;
  	margin-top: 0;
  	min-height: 15.625rem;
	  left: 0px;
}


.sidenav{

	&#sidenavFilterMobile{
		background-color: white;
		z-index:10000000;
	}

	position: fixed;
	height: calc(100vh);
	background-color:$blue;
	top: 85px;
	left: -100%;
	width: 100%;
	@include media-breakpoint-up(md) {
		max-width: 400px;
	}
	z-index: 99;
	-webkit-transition: all .5s ease-in-out;
    -moz-transition:    all .5s ease-in-out;
    -o-transition:      all .5s ease-in-out;
    -ms-transition:     all .5s ease-in-out;
    transition:         all .5s ease-in-out;
	.navbar-nav{
		padding-left:1rem;
		padding-right:1rem;
		.nav-item{
			.nav-link{
				padding: 1rem 0;
				position:relative;
				.fas{
					position: absolute;
					top: 18px;
					right: 25px;
				}
			}
		}
	}
	&.sidenavIIlvl{
		top:0px;
		border-top:none;
		overflow-y:scroll;
		overflow-x:auto;
		padding-bottom: 100px;
		.navbar-nav{
			.nav-item a.secondLvl{
				&:before {
					content: url("../../images/icons/minus-thin.svg");
					display: inline-block;
					position: absolute;
					width: 16px;
					height: 8px;
					transition: all 250ms ease-in-out;
					text-decoration: none;
					color: transparent;
					right: 7px;
					transform: rotate(0deg);
   		 			top: 32%;
				}
				&.collapsed{
					&:before {
						content: url("../../images/icons/plus-thin.svg");
						transform: rotate(0deg);
						top: 32%;
					}
			}
			}
		}
	}
}

.pb-35{
	padding-bottom:1rem;
	@include media-breakpoint-up(lg) {
		padding-bottom:2.188rem;
	}
	
}

.opt-Colore, .opt-Color{
	display:none;
}

.title-navbar {
	font-size: 1.25rem;
	color: $white;
    line-height: 19.2px;
    font-weight: 300;
    text-transform: capitalize;
}


#filter-container{
	.nav-item a{
		color: rgba(255,255,255,.8);
		text-transform: capitalize;
		&.active{
			color:$primary;
			text-transform: initial;
		}
		&.disabled{
			display: none !important;
		}
	}
}
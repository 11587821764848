$color-social:           $gray-400;

@include media-breakpoint-down(md) {
  .dropdown-m {
    width: 100%;
  }
}

.dropdown-cart {
  width: 300px;
}
.dropdown-cart svg{
  fill: #ffffff!important;
}

#sl-map-container {
  height: 100%;
  min-height: 450px;
}

.user-marker-selector {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  background-color: white;
}

#showUserMarkerSelector {
  padding: 10px;
}


.may-also-like {
  position: relative;
  .sticker {
    position: absolute;
    top: 0;
    left: 5px;
  }
}

.jumbotron {
  .featured-text {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: $gray-700;
  height: $carousel-control-width;
  margin-top: -(($carousel-control-width) / 2);
  opacity: 0.9;
  top: 50%;
  width: $carousel-control-width;
  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 47px;
  }
}

#blog-sidebar {
  .input-group-sm {
    & > .form-control {
      padding: 1.125rem .75rem;
      color: $dark;
      border-color: $dark;
    }
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 15px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  font-weight: 900;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background-color: transparent;
    color: transparent;
    &:before {
      opacity: 1;
    }
  }
  &.slick-disabled:before {
    opacity: 0.5;
  }
  &:before {
    font-family: "Font Awesome 5 Free";
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -20px;
  [dir="rtl"] & {
    left: auto;
    right: -20px;
  }
  &:before {
    content: "\f053";
    [dir="rtl"] & {
      content: "\f054";
    }
  }
}

.slick-next {
  right: -20px;
  [dir="rtl"] & {
    left: -20px;
    right: auto;
  }
  &:before {
    content: "\f054";
    [dir="rtl"] & {
      content: "\f053";
    }
  }
}

.applica-filtri{
  opacity: 0;
  transition: opacity 1s linear 1s;
  text-align:left;
  max-width: 396px;
  svg{
    float: right;
    position: relative;
    top: -3px;
  }
}

.openNavActive{
  
  .applica-filtri{
    position:fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 20px 20px 74px;
    opacity: 1;
    background-color: #fff;
  }
}


#sidenavFilterMobile{
  padding:10px;
  padding-bottom: 180px;
  z-index:10000000;
  .filter-title{
    padding-left: 15px !important;
    padding-top: 10px !important;
  }
  .content-filter{
    padding-left:10px;
    padding-right: 10px;
  }
}

.no-collapsable{
  opacity: 0.3;
}

.input-slider{
  width: 100px;
  border: 0px;
  background-color: #827DA8;
}

.page_cms.cnt_706281,
.page_cms.cnt_703089,
.page_cms.cnt_709084,
.page_cms.cnt_709508{

  #site_main .container{
      max-width: 1200px; 
    }

    .embed-responsive{
      margin-top: -250px;
      margin-bottom: -3rem;
       @include media-breakpoint-down(md) {
        margin-top: -150px;
      }
    }

  .landing-page{
    background-color: #2c3672;
    font-size: 14px;
    font-weight: 200;
    padding-bottom:250px;
    @include media-breakpoint-down(md) {
        padding-bottom:150px;
    }
    
    h1{
      font-size: 2.5rem;
      //line-height: 30px;
      padding-bottom: 0px;
      width: 100%;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
        line-height: 2.2rem;
      }
    }
    h2{
      font-weight: 300;
      font-weight: 1.75rem;
    }
    h3{
      font-size: 20px;
      font-weight: 400;
    }

    

    .container.first-block >div{
      > div{
        background-color: #fff;
      }
      >div:first-child{
        @include media-breakpoint-up(md) {
          //width:70%; 
          text-align: center;  
          
        }
        background-color: #2c3672;  
        flex:unset;
      }
      >div:nth-child(2){
        padding-top:40px;
        h3{
          font-weight: 500;
        }
        padding-left:15px;
        padding-right:15px;
        @include media-breakpoint-up(md) {
          padding-top:50px;
          padding-bottom: 50px;
          width:30%;   
        }
        color:#fff;
        background-color: #2c3672;   
        .h4{
          display:none;
        }
        input,select,a.btn{
          border-radius: 6px!important;          
        }
        [type="submit"]{
          width:100%;
        }
      }
    }
  }
}


.page_cms.cnt_706281{
  .landing-page{
    padding-bottom: 80px;
  }
}

.landing-box-slider{
  margin-top:40px;
  margin-bottom:20px;
  @include media-breakpoint-up(md) {

  }
}
.cnt_706281 .landing-page{
  text-align: center;
  .subtitle{
    font-weight: 300;
  }
}

#adb-custom-form-container-identification{
  padding:20px 0px 50px 0px !important;
}

.rendered-form{
  .formbuilder-text-label{
    display: block;
  }
  input[type=text],
  input[type=email],
  input[type=tel]{
    width: 100%;
    border: 1px solid #CBCBCB;
    background-color: transparent;
    padding:10px 10px;
    border-radius: 0.25rem;
    &:focus{
      border: 1px solid black;
    }
    &.adb-invalid{
      border: 1px solid #dc3545;
    }
  }

  input[type=checkbox].adb-invalid{
      outline: 1px solid #dc3545;
      //border: 1px solid #dc3545;
  }

label > input[type="checkbox"] {
  display: none;
}
label > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: black;
  flex-shrink: 0;
  overflow: hidden;
  content: "✓";
  line-height: 1rem;
  color: white;
  background-color: white;
}
label > input[type="checkbox"]:checked + * {
  color:#fcaf17;
}
label > input[type="checkbox"]:checked + *::before {
  content: "✓";
  color: white;
  text-align: center;
  background: #fcaf17;
  border-color: #fcaf17 !important;
  line-height: 1rem;
}
label > input[type="checkbox"].adb-invalid + *::before {
  border-color: #dc3545;
}


label > input[type="checkbox"] + * {
  display: inline-flex;
  padding: 0.5rem 0rem;
}

  button{
    background-color: #fcaf17;
    border-color: #fcaf17;
    color: white;

    display: inline-block;
    font-family: Inter,sans-serif;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    text-transform: uppercase;
    padding: 1rem 2.188rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 0px;
    width: 100%;
  }

  .formbuilder-checkbox{
    margin-top:10px;
  }

}

body.page_confirm,
body.page_cart{
  font-family: $font-family-sans-serif !important;
}


#v-pills-orders{
  .table-header{
    padding-left:.5rem !important;
    padding-right: .5rem !important;
    div{
         text-transform: uppercase;
    font-weight: bold;
    }
   
  }
}

#dealer-filter-container .btn-link{
  color: white;
}

.text-orange{
  color: $orange;
}

.text-blue{
  color: $blue;
}

.semibold{
  font-weight: 500;
}

#logContainer{
  .alert{
    width: 100%;
    .close{
      font-weight: 400;
      font-size: 20px;
      color:#721c24;
    }
  }
}
#cookie_reminder{
   .products-list-header{
  max-height: 100%;
}
  .custom-name,.custom-price{
    color:#000;
  }
  @include media-breakpoint-down(lg){
    .modal-body{
      padding-bottom:100px;
    }
  }
}
#privacy_modal.modal.fade.show{
  z-index: 10000;
}
#cookie_reminder #thanksContainer{
  position: absolute;
  bottom: 100px;
}
.icon-phone-fixed{
  height: 64px;
  width: 64px;
  position: fixed;
  bottom: 16px;
  right: 82px;
  transform: none;
  transform-origin: right bottom;
  border: 0px;
  margin-top: 0px;
  box-shadow: rgba(23, 73, 77, 0.15) 0px 20px 30px;
  animation: 0.2s ease-in 0.5s 1 normal forwards running ww-e747376f-4b19-4e2b-a8ac-1c3eccb217e6-widgetOnLoad;
  z-index: 999999;
  border-radius: 0px;
  background-color: rgb(54, 35, 118);
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width: 42px;
    fill: #fff;
  }
}
.close-cart-button{
  position: absolute;
  right: 14px;
  top: 14px;
  opacity: .5;
  color:#fff;
  font-size:20px;
  &:hover{
    color:#fff;
    text-decoration: none;
  }
}
#header_username,#header_password,#site_header #email{
  color:#fff;
}
#adds_on_popup .modal-dialog{
    width: auto;
    min-width: 0;
    max-width: max-content;
    img{
      max-width: 100%!important;
    }
}
.ADB2_ANIMATEPANEL_box_on_top_false{
  z-index: 1000000!important;
}
body{
  overflow-x: hidden;
}
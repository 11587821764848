.loading {
  @include loading($gray-600, transparent, 80px, 3px, 3);
}

.img-auto {
  height: auto !important;
  width: auto !important;
}

.fade {
  &.in {
    opacity: 1;
    @include transition(opacity 0.5s ease);
  }
}

.color-white {
  color: $white !important;
}

.relative {
  position: relative;
}

// COLLAPSE TOGGLE
// ======================================================
// - requires fontAwesome
// - I'm sorry about the !important
// ======================================================
.collapse-toggle,
.collapse-toggle-upwards {
  position: relative;
  display: block;
  padding-right: 1.5em;
  outline: 0 none !important;

  &:after {
    @include fa-icon();
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    line-height: 1.5rem;
    content: fa-content($fa-var-chevron-right);
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none !important;
    text-align: center;
  }

  &.collapsed:after {
    content: fa-content($fa-var-chevron-down);
  }
}
.collapse-toggle-help {
  @extend .text-left;
  @extend .w-100;
  &:after {
    line-height: 2em;
    right: 0.5em;
  }
}

.collapse-toggle-upwards {
  &:after {
    content: fa-content($fa-var-chevron-down);
    top: 5px;
    right: 7px;
  }
  &.collapsed:after {
    content: fa-content($fa-var-chevron-up);
  }
}

// sets a max height for an element
.max-height {
  max-height: 500px;
  overflow-y: auto;
}

// Creates columns from 1 to 12
@for $i from 1 through $grid-columns {
  .columns-#{$i} {
    columns: $i;
  }
}

@for $i from 1 through 15 {
  .min-w#{$i} {
    min-width: #{$i}em;
  }
}

.dl-bordered > dd {
  border-bottom: 1px solid $gray-400;
}

.table-striped {
  > div {
    border-bottom: 1px solid $gray-300;
    @extend .p-3;
  }
  .table-header {
    @extend .d-none;
    @extend .d-md-flex;
    @extend .font-weight-bold;
    @extend .mt-3;
  }

  div.content-striped {
    &:nth-child(odd) {
      background-color: $gray-100;
    }
  }
}

@include media-breakpoint-down(md){
  .table-striped {
    > div {
      border-bottom: 1px solid $gray-500;
      padding:1rem 0!important;
    }
  }
}

.zg-loading-payment {
  z-index: 9999;
  position: fixed;
  background-color: fade-out($color: $modal-backdrop-bg, $amount: 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  .card {
    width: 100%;
    max-width: 400px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    .loading {
      @include loading($gray-600, transparent, 80px, 3px, 1.5);
    }
  }
}

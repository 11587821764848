.page_category, .page_search, .page_cms{
	font-family: $font-family-ingra-wide;
	.banner_category{
		min-height:200px;
		margin-bottom:50px;
		width:100%;
		overflow: hidden;
		picture>img{
			border-radius: 24px;
		}
		.banner_category_text{
			position:absolute;
			left:0;
			right:0;
			bottom:20%;
			color:#fff;
			h1{
				font-size: 40px;
				line-height: 32px;				
			}
			@include media-breakpoint-down(sm) {
				bottom:16%;
				h1{
					font-size: 24px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			margin-bottom:10px;
			margin-top:20px;
			.text-center.col-12{
				img{
					border-radius: 16px;
				}
			}
			
		}
	}
	
	.category-products {
		a:hover{
			text-decoration: none;
		}
		label{
			display:block;
			text-align: left;
			color: $blue;
			cursor:pointer;
			&.name {
				//min-height: 44px;
				font-size: 18px;
				color: $black;	
				margin-bottom: 5px;
				//padding-right: 40px;	
			}
			&.brand{
				background: #0C4365;
				display:inline-block;
				font-weight: 500;
				font-size: 10px;
				line-height: 15px;
				letter-spacing: -0.02em;
				text-transform: capitalize;
				text-align:center;
				padding:3px 8px;
				color:#fff;
				border-radius: 16px;
			}
			&.options-prod{			
				line-height: 16px;
				letter-spacing: -0.02em;	
				padding-bottom:14px;
				border-bottom:solid 1px #DEDEDE;	
				font-weight: bold;
				font-size: 14px;	
				margin-bottom: 14px;	
			}
			&.description{
				line-height: 16px;
				letter-spacing: -0.02em;
				font-weight: 500;
				font-size: 14px;
			}
			&.text-price{
				font-weight: 500;
				font-size: 14px;
				line-height: 28px;
				letter-spacing: -0.02em;
				display:block;
				float:left;
				height: 28px;
				
			}
			&.price{
				display:block;
				font-weight: bold;
				//float:left;
				font-size: 18px;
				padding-left:8px;
				>span{
					font-family: $font-family-ingra-wide;
					color:$black;
					height: 28px;
					font-weight: 600;
					font-size: 23px;
					line-height: 32px;
				}
			}
		}		
	}
	.btn-pagination{
		border: solid 1px $blue;
		color:$blue;
		padding:12px 60px;
		border-radius:2px;		
		text-transform:uppercase;
		font-size:16px;
		font-weight: 700;
	}
	.container-btn-wishlist{
		display:block!important;
		right:10px!important;
		bottom: -20px;
		z-index: 98;
		@include media-breakpoint-down(md) {
			bottom: -15px;
			right:7px!important;
		}
		.btn{
			box-shadow: 3px 3px 9px 0 rgba(0,0,0,.1);
			background-color: white;
			padding: 10px;
			border-radius: 20px;
			&:hover{
				background-color: $orange;
			}
			@include media-breakpoint-down(md) {
				padding: 5px;
				svg{
					height: 18px;
					width: 18px;
				}
			}
		}
	}
	/*ONLUY MOBILE VERSION*/
	@include media-breakpoint-down(md) {
		/*.container-category{
			padding:0 9px;
		}*/
		.btn-filter{
			margin-top: 20px;
			display:block;
			border: solid 1px $white;
			color:$white;
			margin-bottom: 24px;
			border-radius:200px;		
			text-transform:uppercase;
			font-size:16px;
			font-weight: lighter;
			width:100%;
		}
		filter-wrapper{
			/*transition: .5s;	
			position: fixed;
			height: calc(100vh - 20px);
			background-color: #fff;
			top: 0;
			right: -100%;
			width: 100%;
			padding: 1.75rem 1.125rem;
			overflow-y: scroll;
			overflow-x: hidden;
			padding-bottom:120px;
			z-index: 1010;
			overflow:visible;*/
			.header-filter-mobile{
				position:absolute;
				display: flex;
				left:0;
				right:0;
				top:0;
				text-align: left;
				height: 56px;
    			line-height: 56px;
				border-bottom:solid 1px #E6E6E6;
				>*{
					height: 56px;
					line-height: 56px;
					.h3{
						height: 56px;
						line-height: 56px;
					}
				}
				.filter-reset{
					position:static;
					margin-top:16px;
				}
			}
			#filter-container{
				display:block;
				overflow: auto;
    			max-height: 100%;
				margin-top:56px;
			}
			.closeFilter{
				background:#F78E42;
				width:56px;
				height:56px;
				position:absolute;
				right:0;
				top:0;
				text-align:center;
				line-height: 56px;
				color:#fff;
				z-index:101;
			}
			.h2-small{
				font-family:$font-family-sans-serif;
				font-size:1.313rem;
			}

		}
	}



	.pagination-controls{
		display: flex; 
		flex-direction: column; 
		align-items:center; 
		justify-content: center;
	}
	.pagination{
		padding: 0px;
		margin: auto 10px;

		@include media-breakpoint-down(md) {
			display: none;
  		}

		li.disabled{
			pointer-events: auto! important;
     		cursor: not-allowed! important;
			opacity: .5;
		}
		li.arrow-right,li.arrow-left{
			position:relative;
			padding: 0 ;
    		text-align: center;
			width:50px;
			@include media-breakpoint-down(md) {
				height: 50px;
  			}
			a{
				position:absolute;
				top:12px;
				display: block;
				text-align: center;
				width: 100%;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.page-item{
			padding:8px 20px;
			cursor: pointer;
			border:solid 1px #686868;
			border-radius: 5px;
			color: #686868;
			margin:0px 5px;
			
			&.active{
				background-color: #fcaf17;
				color: #fff;
			}
		}
	}


}




.product-col{
	background-color: #f8f8f8;
	overflow: hidden;
	@include media-breakpoint-up(sm){
		padding: 60px 30px;
	}
	.category-products{
		//margin: auto;
		padding: 5px;
		@include media-breakpoint-up(sm){
			padding: 10px;
		}
		.card{
			height: 100%;
			.card-body{
				padding: 1rem 0.5rem;
				@include media-breakpoint-up(sm){
					padding: 1rem;
				}
			}
			//width: 321px;
			font-family: $font-family-ingra-wide;
			border-radius: 1.25rem;
			box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 10%);
			&:hover{
				box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 20%);
			}
			background-color: white;
			@include media-breakpoint-down(sm){
				margin: auto;
			}
			@media only screen 
				and (min-device-width: 768px) 
				and (max-device-width: 1024px) 
				and (orientation: portrait) 
				and (-webkit-min-device-pixel-ratio: 1) {
    				width: 100%;
			}
			.product-content{
				.images-container{
					aspect-ratio: 4 / 3;
					display: block;
					overflow: hidden;
					img{
						border-top-right-radius: 1.25rem;
						border-top-left-radius: 1.25rem;
					}
				}
			}

			.product-options.card-body{
				> .row{
					height: 100%;
				}
				.card-title{
					color: $black;
					font-family: $font-family-ingra-wide;
					font-weight: 600;
					font-size: 1rem;
					letter-spacing: -.02em;
					@include media-breakpoint-up(sm){
						font-size: 1.2rem;
					}
				}
				.card-text{
					color: #5f6973;
					font-family: $font-family-ingra-wide;
					font-weight: 400;
					font-size: .75rem;
					//line-height: 32px;
					//min-height: 40px;
				}
				.price{
					display:block;
					font-weight: bold;
					//float:left;
					font-size: 1rem;
					//padding-left:8px;
					//min-height: 50px;
					//margin: 0px 0px 16px 0px;
					line-height: 100%;
					>span{
						font-family: $font-family-ingra-wide;
						color:$black;
						height: 28px;
						font-weight: 600;
						font-size: 1rem;
						line-height: 16px;
						@include media-breakpoint-up(sm){
							font-size: 23px;
							line-height: 32px;
						}
					}
					del{
						font-family: $font-family-ingra-wide;
						font-size: 1rem;
						font-weight: normal;
						opacity: 0.5;
					}
				}
				.btn.btn-primary{
					width: 100%;
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 19px;
					text-align: center;
					//letter-spacing: 0.15em;
					text-transform: uppercase;
				}
			}
		}
	}	
}

body.page_home{
	.product-col{
		background-color: transparent;
		padding: 0px 0;
		@media (min-width: 320px) and (max-width: 767px) {
		padding: 0px 0 30px;
		}
	}
}

#products-container{
	.search-results{
		#product-finder{
			.input-group{
				margin-top: 20px;
				span{
					position: absolute;
					left: 15px;
					top: 13px;
					svg{
						width: 25px;
						height: 25px;
					}
				}
				input{
					//max-width: 751.24px;
					//height: 80.59px;
					border: 1px solid #000000;
					border-radius: 50px;
					//margin-left: 38px;
					background-color: transparent;
					//padding-top: 1.75rem;
					padding-left: 3rem;
					margin-right: 20%;
					&::placeholder{
						font-family: $font-family-ingra-wide;
						//font-weight: lighter;
						font-size: 1rem;
						line-height: 42px;
						color: black;
						padding-left: 0px;
						padding-top: 25px;
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}
	.sortByCol{
		//padding-right: 4.5rem;
		span.sortByText{
				font-family: $font-family-ingra-wide;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				display: flex;
				align-items: center;
				text-align: right;
				letter-spacing: 0.15em;
				text-transform: uppercase;
		}
		button{
			font-weight: 300;
			display: flex;
			align-items: center;
		}
		.dropdown-sortBy{
			.dropdown-menu{
				top: -2px !important;
				left: 21px !important;
			}
		}
		button.dropdown-toggle{
			&::after{
					font-size: 14px;
					//line-height: 24px;
					letter-spacing: -0.02em;
					cursor:pointer;
					//border: 2px solid $white;
					box-sizing: border-box;
					border-radius: 200px;
					padding-left: 15px;
					text-align: center;
					color: $white;
					text-decoration: none;
					content: url(../../images/icons/arrow-down.svg);
					border: none;
			}
		}
	}
}


a.btn-filter-mobile{
	border: 1px solid #ccc;
	color: grey;
	padding: 8px;
	margin-top: 10px;
	&:active{
		background-color: white;
		border: 1px solid #ccc;
		color: grey;
	}
	svg{
		transform: rotate(45deg);
	}
}

.filter-mobile{
	
	#filter-container{
		.filter{
			.content-filter{
			padding-bottom: 20px;
				&:last-child{
					margin-bottom:0;
				}
				a{
					//border-color: $blue;
					font-weight: 300;
					font-size: 14px;
					line-height: 32px;
					text-transform: lowercase;
					color: $white;
					//padding-left: 2.6em;
					margin-bottom: 0px;
					&:last-child{
						margin-bottom:0;
					}
					&::first-letter{
						text-transform: uppercase;
					}
					&:before{
						border: solid 2px #f2f6f5;
						width: 20px;
						height: 20px;
						top: 9px;
						left: 10px;
					}
					&.active:before {
						//background-image:url("#{$img-path-theme}/custom/checkCategoryFilter.png");
						content: "";
						color: #fff;
						background-color: $white;
						border-color: $white;
						background-repeat: no-repeat;
						background-position: center;
						background-size: auto;
					}
				}
			}
		} 
	}
}
.dropdown-mobile{
	position: absolute;
    right: 4px;
	button.dropdown-toggle{
		&::after{
					font-size: 14px;
					padding-left: 32px;
					letter-spacing: -0.02em;
					cursor:pointer;
					box-sizing: border-box;
					text-align: center;
					color: $white;
					text-decoration: none;
					content: url(../../images/icons/arrow-down-white.svg);
					border: none;
		}
	}
	.dropdown-menu{
		left:5px;
		&.show{
			button.dropdown-toggle{
				&::after{
							font-size: 14px;
							padding-left: 32px;
							letter-spacing: -0.02em;
							cursor:pointer;
							box-sizing: border-box;
							text-align: center;
							color: $white;
							text-decoration: none;
							content: url(../../images/icons/arrow-up-white.svg);
							border: none;
				}
			}
		}
		a{
			padding: 0.25rem 1rem;
			span{
				color:$white;
			}
		}
	} 
}

.btn-outline-black {
    color: $black;
    border-color: $black;
}

.btn-outline-white {
    color: $white;
    border-color: $white;
}

.text-bold{
	font-weight: 600;
}
#products-container{
	.search-mobile{
		#product-finder{
			.input-group{
				span{
					top: 13px;
				}
				input{
					border: 1px solid $white;
					height: 50px;
					padding: 1rem 2.188rem 1rem 3.75rem;
					color: $white;
					&::placeholder{
						color: $white;
						font-weight: 400;
						font-size: 20px;
						line-height: 32px;
						padding-left: 12px;
					}
				}
			}
		}
	}
}


#filter-container{
	@include media-breakpoint-up(sm){
		display: block;
	}
	@include media-breakpoint-down(sm) {
		//width: 165px;
		.filter{
			.header-filter{
				a.filter-name{
					width: 100%; 
					display: flex; 
					justify-content: space-between;
					padding: 30px 10px;
					font-size: 0.75rem;
					&::after{
						font-size: 14px;
						padding-left: 32px;
						letter-spacing: -0.02em;
						cursor:pointer;
						box-sizing: border-box;
						text-align: center;
						color: $white;
						text-decoration: none;
						content: url(../../images/icons/arrow-up-white.svg);
						border: none;
					}
					 &.collapsed::after{
						padding-left: 0px;
						letter-spacing: -0.02em;
						cursor:pointer;
						box-sizing: border-box;
						text-align: center;
						color: $white;
						text-decoration: none;
						content: url(../../images/icons/arrow-down-white.svg);
						border: none;
					 }
				}
			}
		}
	}
}

.column-filters{
	//background-color: rgba(0,0,0,.03);
	@include media-breakpoint-up(md) {
		background-color: transparent;
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 22%;
    	max-width: 22%;
		padding-left: 30px;
	}
}

.container-category{
	.product-col{
		@include media-breakpoint-up(xl) {
			flex: 0 0 78%;
			max-width: 78%;
		}
	}
}


.ranking-info{
	p{
		margin-bottom: 0px;
		text-decoration: underline;
		text-transform: uppercase;
	}
}

.article-link{
	z-index: 90;
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
}

.section-ht{
	//min-height: 150px;
	.ht-info{
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	padding-top: 10px;
	padding-bottom: 2px;
		@include media-breakpoint-down(sm) {
			padding-bottom: 10px;
		}
		i{
			color: #5f6973;
			font-size: 1rem;
			margin-right: 5px;
			position: relative;
			top: 2px;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		p{
			//border: 1px solid;
			//border-radius: 50px;
			padding: 0px;
			margin-right: 15px;
			margin-bottom: 10px;
			font-size: 0.75rem;
			display: inline-block;
			color: #5f6973;
			@include media-breakpoint-down(sm) {
				margin-right: 5px;
				margin-bottom: 0px;
			}
		}
		svg{
			width: 1rem;
			height: 1rem;
			display: inline-block;
			line-height: 1em;
			flex-shrink: 0;
			color: #5f6973;
			vertical-align: middle;
			fill: transparent;
			stroke: transparent;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
	@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) 
		and (orientation: portrait) 
		and (-webkit-min-device-pixel-ratio: 1) {
    	//display: none;
	}
}



.dropdown-desktop{
	.dropdown-menu{
		background-color: $white;
	}
}


.remove-filter{
	background-color: #fcaf17;
    border-radius: 4px;
	//opacity: 0.48;
	display:inline-block;
	padding:7px 14px;
	color:#ffffff;
	font-size:0.875rem;
	margin-bottom:5px;
	margin-right: 5px;
	svg{
		position: relative;
		top: -1px;
	}
	&:hover{
		text-decoration: none;
		color: white;
	}
	
}

#active-filters{
	padding: 0px;
	color: white;
}

.wishlist-icon{
	position: absolute;
    top: 13px;
}

.carousel-hp{
	.remove-filter{
		background-color: #fcaf17;
    	border-radius: 4px;
    	padding: 7px 14px;
    	margin-right: 10px;
		//display: block;
    	width: auto;
	}
	#active-filters{
		position: absolute;
		top: 50px;
		left: 0;
		padding: 15px 0;
			@media (min-width: 768px) and (max-width: 991px) {
				top: 120px;
				left: 120px;
				width: 82%;
				div{
					display: flex;
					flex-wrap: wrap;
				}
			}
	}
}



#filter_char_2198,
#filter_char_2244,
#filter_char_2245,
#filter_char_1203,
#filter_char_8,
#filter_char_2206,
#filter_char_2202,
#filter_char_2200,
#filter_char_2201,
#filter_char_2200,
#filter_char_2226,
#filter_char_2211,
#filter_char_2212,
#filter_char_2213,
#filter_char_2214,
#filter_char_2220,
#filter_char_2196,
#filter_char_2221,
#filter_char_2238,
#filter_char_2187,
#filter_char_2237,
#filter_char_2234,
#filter_char_2240,
#filter_char_2204,
#filter_char_2249,
#filter_char_2248,
#filter_char_2235,
#filter_char_2236,
#filter_char_2246,
#filter_char_2250
{
	display: none;
}

#filter_char_2210 .content-filter a:last-child{
	display: none!important;
}

.content-filter input{
	border: solid 1px #ccc;
    border-radius: 4px;
	margin-bottom: 4px;
}
#filter_char_2247 .content-filter input,
#filter_char_2186 .content-filter input,
#filter_char_2259 .content-filter input,
#filter_char_2242 .content-filter input
{
	display: none;
}

.dropdown-sortBy.dropdown-desktop{
	.dropdown-toggle{
		width: 250px;
		text-align: left !important;
		&::after{
			content: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.945312 0.386719L7.39907 6.53315L13.8528 0.386719' stroke='black'/%3E%3C/svg%3E");
			border:0px;
			float: right;
		}
		


	}
	dropdown-menu{
		min-width: 250px;
	}
	/*.dropdown-menu{
		width: 250px;
		border-radius: 1.5rem;
		border-color: black;
		overflow: hidden;
	}*/
}


.container-category{
	background-color: #f8f8f8;

	#filter-wrapper{
		@include media-breakpoint-up(md) {
			border-radius: 4px;
			background: #fff;
			box-shadow: rgba(72, 88, 133, 0.12) 0px 4px 16px;
			margin-top: 3rem;
		}
	}

	#filter-container{

		.filter-label{
			font-size: 0.813rem;
			font-weight: bold;
			text-transform: uppercase;
			color: #2c3672;
		}

		.dropdown{

			.dropdown-toggle::after{
				display: none;
			}

			.label-dropdown{
				padding: 2px 8px;
				width: calc(100% - 40px);
			}


			

			.btn-secondary{
				-webkit-box-align: center;
				align-items: center;
				cursor: default;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: justify;
				justify-content: space-between;
				min-height: 40px;
				position: relative;
				transition: all 100ms ease 0s;
				background-color: rgb(255, 255, 255);
				border-color: rgb(204, 204, 204);
				border-radius: 4px;
				border-style: solid;
				border-width: 1px;
				box-sizing: border-box;
				outline: 0px !important;
				color: rgb(128, 128, 128);
				text-transform: capitalize;
				padding: 0px;

				&:hover{
						border-color: rgb(179, 179, 179);
				}

				.icon-arrow-container{
					.indicatorSeparator{
						-webkit-align-self: stretch;
						-ms-flex-item-align: stretch;
						align-self: stretch;
						width: 1px;
						background-color: hsl(0, 0%, 80%);
						margin-top: 5px;
						box-sizing: border-box;
						display: block;
						height: 22px;
						float: left;
					}
				}


				.icon-arrow{
					display: flex;
					-webkit-transition: color 150ms;
					transition: color 150ms;
					color: hsl(0, 0%, 80%);
					padding: 8px;
					box-sizing: border-box;

					svg{
						display: inline-block;
						line-height: 1em;
						flex-shrink: 0;
						color: rgb(72, 88, 133);
						vertical-align: middle;
						width: 1rem;
						height: 1rem;
						transform: rotate(0deg);
						transition: transform 0.2s ease-in 0s;
					}
				}
				
			}

			.dropdown-menu{
				background-color: white;
				max-height: 300px;
				overflow: scroll;
				border-radius: 4px;
				border-color: rgb(204, 204, 204);
				border-radius: 4px;
				border-style: solid;
				border-width: 1px;
				box-sizing: border-box;
				
				.content-filter{
					margin-bottom: 0px;
				}

				a{
					display: block;
					padding: 10px;
					color: rgb(128, 128, 128);
					text-transform: capitalize;
					&:hover{
						background-color: rgba(44, 54, 114, 0.3);
						color: white;
						text-decoration: none;
					}
				}
			}


			&.show{
				.btn-secondary{
					.icon-arrow{
						svg{
							transform: rotate(180deg);
						}
					}
				}
			}
		
		}

		.btn-primary{
			padding: 11px;
			font-weight: 500;
			letter-spacing: 1px;
			text-transform: none;
		}


		#filter_char_1200.filter .content-filter a.disabled{
			color: grey !important;
			cursor: inherit;
			
		}

	}

}

.page_category .container-category{
	#filter_char_1200.filter .content-filter a.disabled:not(.hidden){
		display: block !important;
	}
}



.container-category #filter-container .dropdown .dropdown-menu a[data-filter="char_2199"]{text-transform:none;}

#filter_char_2199{
	[data-filter="char_2199"][data-value="10"]::after{ content: " CV (8 kW)";}
	[data-filter="char_2199"][data-value="20"]::after{ content: " CV (15 kW)";}
	[data-filter="char_2199"][data-value="30"]::after{ content: " CV (22 kW)";}
	[data-filter="char_2199"][data-value="40"]::after{ content: " CV (30 kW)";}
	[data-filter="char_2199"][data-value="50"]::after{ content: " CV (37 kW)";}
	[data-filter="char_2199"][data-value="60"]::after{ content: " CV (44 kW)";}
	[data-filter="char_2199"][data-value="70"]::after{ content: " CV (52 kW)";}
	[data-filter="char_2199"][data-value="80"]::after{ content: " CV (59 kW)";}
	[data-filter="char_2199"][data-value="90"]::after{ content: " CV (67 kW)";}
	[data-filter="char_2199"][data-value="100"]::after{ content: " CV (74 kW)";}
	[data-filter="char_2199"][data-value="110"]::after{ content: " CV (81 kW)";}
	[data-filter="char_2199"][data-value="120"]::after{ content: " CV (89 kW)";}
	[data-filter="char_2199"][data-value="130"]::after{ content: " CV (96 kW)";}
	[data-filter="char_2199"][data-value="140"]::after{ content: " CV (103 kW)";}
	[data-filter="char_2199"][data-value="150"]::after{ content: " CV (111 kW)";}
	[data-filter="char_2199"][data-value="160"]::after{ content: " CV (118 kW)";}
	[data-filter="char_2199"][data-value="170"]::after{ content: " CV (125 kW)";}
	[data-filter="char_2199"][data-value="180"]::after{ content: " CV (133 kW)";}
	[data-filter="char_2199"][data-value="190"]::after{ content: " CV (140 kW)";}
	[data-filter="char_2199"][data-value="200"]::after{ content: " CV (148 kW)";}
	[data-filter="char_2199"][data-value="220"]::after{ content: " CV (162 kW)";}
	[data-filter="char_2199"][data-value="240"]::after{ content: " CV (177 kW)";}
	[data-filter="char_2199"][data-value="260"]::after{ content: " CV (192 kW)";}
	[data-filter="char_2199"][data-value="280"]::after{ content: " CV (206 kW)";}
	[data-filter="char_2199"][data-value="300"]::after{ content: " CV (221 kW)";}
	[data-filter="char_2199"][data-value="350"]::after{ content: " CV (258 kW)";}
	[data-filter="char_2199"][data-value="400"]::after{ content: " CV (295 kW)";}
	[data-filter="char_2199"][data-value="500"]::after{ content: " CV (368 kW)";}
	[data-filter="char_2199"][data-value="600"]::after{ content: " CV (442 kW)";}
	[data-filter="char_2199"][data-value="700"]::after{ content: " CV (515 kW)";}
	[data-filter="char_2199"][data-value="800"]::after{ content: " CV (589 kW)";}
}


#filter_char_1204 [data-filter="char_1204"]::after{ content: " €";}

.container-category #filter-container .dropdown .dropdown-menu a[data-filter="char_2215"]{text-transform:none;}
#filter_char_2215 [data-filter="char_2215"]::after{ content: " km";}


.container-category #filter-container .dropdown .dropdown-menu a[data-filter="char_2195"]{text-transform:none;}
#filter_char_2195  [data-filter="char_2195"]::after{ content: " cm³";}



.content_page_dealer [data-filter="char_2228"]{
	display: none;
}
.dropdown-sortBy{

			.dropdown-toggle::after{
				display: none;
			}

			.label-dropdown{
				padding: 2px 8px;
				width: calc(100% - 40px);
			}


			

			.btn-sortBy{
				-webkit-box-align: center;
				align-items: center;
				cursor: default;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: justify;
				justify-content: space-between;
				min-height: 40px;
				position: relative;
				transition: all 100ms ease 0s;
				background-color: rgb(255, 255, 255);
				border-color: rgb(204, 204, 204);
				border-radius: 4px;
				border-style: solid;
				border-width: 1px;
				box-sizing: border-box;
				outline: 0px !important;
				color: rgb(128, 128, 128);
				text-transform: capitalize;
				padding: 0px;

				&:hover{
						border-color: rgb(179, 179, 179);
				}

				.icon-arrow-container{
					.indicatorSeparator{
						-webkit-align-self: stretch;
						-ms-flex-item-align: stretch;
						align-self: stretch;
						width: 1px;
						background-color: hsl(0, 0%, 80%);
						margin-top: 5px;
						box-sizing: border-box;
						display: block;
						height: 22px;
						float: left;
					}
				}


				.icon-arrow{
					display: flex;
					-webkit-transition: color 150ms;
					transition: color 150ms;
					color: hsl(0, 0%, 80%);
					padding: 8px;
					box-sizing: border-box;

					svg{
						display: inline-block;
						line-height: 1em;
						flex-shrink: 0;
						color: rgb(72, 88, 133);
						vertical-align: middle;
						width: 1rem;
						height: 1rem;
						transform: rotate(0deg);
						transition: transform 0.2s ease-in 0s;
					}
				}
				
			}

			.dropdown-menu{
				background-color: white;
				max-height: 300px;
				overflow: scroll;
				border-radius: 4px;
				border-color: rgb(204, 204, 204);
				border-radius: 4px;
				border-style: solid;
				border-width: 1px;
				box-sizing: border-box;
				
				.content-filter{
					margin-bottom: 0px;
				}

				a{
					display: block;
					padding: 10px;
					color: rgb(128, 128, 128);
					text-transform: capitalize;
					&:hover{
						background-color: rgba(44, 54, 114, 0.3);
						color: white;
						text-decoration: none;
					}
				}
			}


			&.show{
				.btn-secondary{
					.icon-arrow{
						svg{
							transform: rotate(180deg);
						}
					}
				}
			}
		
		}
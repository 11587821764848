#site_footer{
	font-family: $font-family-ingra-wide;
	background-color: $blue;	
	color: $white;

	.panel-default{
		
		.panel-heading{
			
			.panel-title {
				padding: 20px 0px;
				border-bottom: 1px solid white;
				margin: 0;
				a{
					color: $white;
					font-family: $font-family-ingra-wide;
					font-size: 1.25rem;
					font-weight: 400;
				}
			}
			a{
				
				&:hover,
				&:focus{
					text-decoration:none;
				}
				
				&:before {
					content: url("../../images/icons/minus-thin.svg");
					display: inline-block;
					position: absolute;
					width: 16px;
					height: 8px;
					transition: all 250ms ease-in-out;
					text-decoration: none;
					color: transparent;
					right: 22px;
					transform: rotate(0deg);
   		 			top: 32%;
				}
				
				
				
			}
			
			a.collapsed{
				
				&:before {
					content: url("../../images/icons/plus-thin.svg");
    				transform: rotate(0deg);
					top: 32%;
				}
			}
		}
	}
	

	.panel-body {
		.list-unstyled {
			li {
				padding: 10px 17px;
			}
		}
	}

	

	li {
		a{
			color: $white;
		}
	}

	.bg-red{
		background-color: $primary;
		small,a{
			color:$white;
		}
	}
	
	.logo-container-footer{
		img{
			width:183px;
		}
	}
	@include media-breakpoint-down(md){

		.footer-log{
			max-width: 75%;
			text-align: center;
			margin: 0 auto;
		}

		.logo-container-footer{
			img{
				width:183px;
				margin-left: 0!important;
			}
		}
		#accordion{
			width: 100%;
			.card{
				background-color: transparent;
    			border: none;
				border-bottom:1px solid rgba(0,0,0,.125);
				.card-header{
					padding:0;
				}
				.card-body{
					padding: 0.5rem 2.5rem;;
				}
				.btn-link{
					padding: 1.5rem 2.5rem;
					text-decoration: none;
					color: $primary;
					font-size: 16px;
					width: 100%;
    				font-weight: 400;
    				text-align: left;
					svg.plus{
						position:absolute;
						right: 41px;
						top: 24px;
					}
					svg.minus{
						position:absolute;
						height: 2px;
						width: 20px;
						top: 31px;
    					right: 39px;
						visibility: hidden;
					}
					&[aria-expanded=true] svg{
						&.plus{
							visibility: hidden;
						}
						&.minus{
							visibility: visible;
						}
					}
				}
				
			}
		}
	}
}

footer{
	.navbar-toggler{
		&:focus{
		outline: none;       
		}
	}
	.custom-control-label{
		font-weight: 300;
		font-size: 11px;
		}
	.fab{
		font-size:20px;
	}
	.social-icon-rounded svg{
		fill: white;
		height: 18px;
    	width: 18px;
    	margin-top: -6px;
	}
}

.newsletter-footer{
	padding: 80px 0px;
	background-color: white;
	@include media-breakpoint-down(xs){
		padding: 40px 0px;
		h3{
			font-size:20px;
		}
	}
	#newsletter{
		input[type="email"]{
			&::-webkit-input-placeholder {
				color: #999999;
				font: 1rem;
			}
		}
		button{
			text-transform: initial;
		}
	}
}


.container-footer {
	
	padding: 100px;
	.dropdown-item {
		border-radius: 25px;
	}
}

.font-12 {
	font-size: 0.75rem;
}

.font-16 {
	font-size: 0.8rem;
	line-height: 1;
}

.font-20 {
	font-size: 1.25rem;
	line-height: 2rem;
}

.font-22 {
	font-size: 1.375rem;
}

.social-block {
	.fa-stack {
		line-height: inherit;
		border-radius: 50%;
		border: 1px solid rgba(248,248,248,.2);
		width: 36px;
		height: 36px;
		display: inline-block;
		text-align: center;
	}
}

.title-opt {
	font-weight: 500;
}

.btn-news {
	padding: 12.5px;
    border: none;
    border-radius: 0 12.5rem 12.5rem 0;
	background-color: rgba(255,255,255,.3);
    color: $white;
}

.form-news {
	border-radius: 12.5rem 0 0 12.5rem;
}

.social-icon-rounded {
	padding: 9px 10px;
    border: 1px solid $white;
    border-radius: 25px;
}

.btn-dropdown {
    box-sizing: border-box;
    border-radius: 40px;
    //height: 56px;
    font-weight: 300;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-dropdown-menu {
	background-color: $white!important;
	color:$white!important;
	border-radius: 25px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
}


#newsletterModal {
	color: $black;
}

.news-title {
	font-size: 1.25rem;
	line-height: 32px;
}

#lang-selector {
	li {
		a {
			color: $black;
			padding: 20px;
			font-size: 1rem;
		}
	}
}

#ns_email::placeholder {
  color:white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
}

.mobile-divider {
	border-top: 1px solid white;
    width: 92%;
}
#account-area {
	&.nav-pills-collapse{
		.nav-link{
			font-size: 17px;
			line-height: 20px;
			@include media-breakpoint-up(lg){
				color: $primary;
			}
			
			&.active{
				color: $blue;
			}
		}
	}
	.tab-content, .card-content {
	
		font-size: 14px;
		
		.card-account {
			//background-color:rgba(0,0,0,.03);
			//height: 250px;
		}

		.address-list{
			>div{display:none;}
			>div.add-shipping{
				display:block;
				flex: 0 0 100%!important;
    			max-width: 100%!important;
				padding: 0;
				margin-top:0!important;
			}
		}
		.card-custom {
			//background-color:rgba(0,0,0,.03);
			//height: 350px;
			dt{font-size:12px;}
			dd{
				font-size:20px;
				line-height: 30px;
    			margin-bottom: 15px;
			}
		}
		.card-address{
			background-color:#fff!important;
		}
		.panel-heading{
			padding: .75rem 1.25rem;
		}
	}

	#tabs-account{
		&.nav-tabs{
			//border-color:transparent;
		}

		.nav-item {
			width: 75%;
			padding:0;
		}

		.border-color {
			//border-bottom:2px solid #000;
		}

		.nav-item a {
			text-decoration: none;
		}

		.nav-item a.active {
			//border-bottom:2px solid #000;
		}

		.nav-ref {
			text-transform:none;
			padding:22px 0;
			color:#e1dbd0;
			&.active {
				color: #000;
			}
		}


	 .h5{
			margin:0;
		}

	}

}

#addresses .card-footer {
	border: none!important;
}

.add-address{
	position: absolute;
    right: 18px;
    top: 95px ; //-27px;
    font-size: 0.625rem;
}

.panel-padding{
	padding: .75rem 1.25rem;
}


.color-banner{
	background: $blue;
	border-radius: 24px;
	width: 100%;
	height: 100px;
	@include media-breakpoint-up(lg){
		height: 240px;
	}
	/*left: 119px;
	top: 172px;*/
}

.row-info{
	display: flex;
    justify-content: space-between;
	top: 163px;
	@include media-breakpoint-down(xs){
	top: 10px;
	flex-direction: column;
	margin-bottom: 30px;
	}
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 1) {
		top: 10px;
		flex-direction: column;
		margin-bottom: 30px;
	}
	.info-profile{

		@include media-breakpoint-down(xs){
		
			border-bottom: 1px solid #ededed;
    		padding-bottom: 20px;
		}
		.round-profile{
			width: 160px;
			height: 160px;
			border-radius: 50%;
			background-color: #e1dbd0;
			font-size: 48px;
			color: grey;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(xs){
				width: 64px;
				height: 64px;
				font-size: 18px;
			}
			@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
				width: 100px;
				height: 100px;
			}
		}
		.info-profile-text{
			margin-left: 20px;
			h5{
				font-size: 28px;
				font-weight: 500;
				@include media-breakpoint-down(xs){
					font-size: 18px;
					margin: 0px;
				}
			}
			p{
				@include media-breakpoint-down(xs){
					font-size: 14px;
					margin: 0;
    				padding-top: 10px;
				}
			}
		}
	}
	.info-options{
		display: flex;
    	align-items: center;
		@include media-breakpoint-down(xs){
			justify-content: space-between;
			padding-top: 35px;
		}
		@media only screen 
			and (min-device-width: 768px) 
			and (max-device-width: 1024px) 
			and (orientation: portrait) 
			and (-webkit-min-device-pixel-ratio: 1) {
			justify-content: space-between;
			padding: 36px 0px 20px;
		}
		a.info-options-home,.info-options-config,.info-options-order,.info-options-fav{
				width: 96px;
				height: 96px;
				background: #FFFFFF;
				border: 1px solid #E6E6E6;
				box-sizing: border-box;
				border-radius: 50%;
				margin-left: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include media-breakpoint-down(xs){
					width: 64px;
					height: 64px;
					margin: 0px;
				}
				@media only screen 
					and (min-device-width: 768px) 
					and (max-device-width: 1024px) 
					and (orientation: portrait) 
					and (-webkit-min-device-pixel-ratio: 1) {
					margin-left: 0px;
				}
					.icon{
						display: inline-block;
						background-position: 50% 50%;
						width: 40px;
						height: 40px;
						background-color: transparent;
						@include media-breakpoint-down(xs){
							width: 22px;
							height: 22px;
						}
					}
					.icon-home{
						background-image: url("../../images/icons/home-color.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/home-color-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-config{
						background-image: url("../../images/icons/settings.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/settings-color-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-order{
						background-image: url("../../images/icons/list.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/list-color-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-fav{
						background-image: url("../../images/icons/heart.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/heart-color-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.text-icon{
						position: absolute;
						bottom: -15px;
						color: #0C4365;
						@include media-breakpoint-down(xs){
							font-size: 12px;
							bottom: -42px;
						}
						@media only screen 
							and (min-device-width: 768px) 
							and (max-device-width: 1024px) 
							and (orientation: portrait) 
							and (-webkit-min-device-pixel-ratio: 1) {
								bottom: -37px;
						}
					}
				&:hover{
					background-color: #FF9E38;
					border: 1px solid #FF9E38;
					.icon-home{
						background-image: url("../../images/icons/home-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/home-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-config{
						background-image: url("../../images/icons/setting-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/setting-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-order{
						background-image: url("../../images/icons/list-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/list-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-fav{
						background-image: url("../../images/icons/heart-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/heart-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.text-icon{
						color: #FF9E38;
					}
				}
				&:active{
					background-color: #FF9E38;
					border: 1px solid #FF9E38;
					.icon-home{
						background-image: url("../../images/icons/home-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/home-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-config{
						background-image: url("../../images/icons/setting-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/setting-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-order{
						background-image: url("../../images/icons/list-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/list-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.icon-fav{
						background-image: url("../../images/icons/heart-white.svg");
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/heart-white-mobile.svg");
							background-repeat: no-repeat;
						}
					}
					.text-icon{
						color: #FF9E38;
					}
				}
			}
	}
}


.absolute{
	position: absolute !important;
}

.account-info{
	
	@include media-breakpoint-down(xs){
		margin-top: 0px;
	}
	@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) 
		and (orientation: portrait) 
		and (-webkit-min-device-pixel-ratio: 1) {
		margin-top: 50px;
	}
	#v-pills-tab{
		box-sizing: border-box;
		border-radius: 24px;
		justify-content: space-around;
		font-size: 1rem;
	}
	.nav-pills{
		padding: 20px;
		.nav-link{
			padding: 0px 10px;
			border-right: none;
			text-transform: none;
			font-weight: 600;
		}
	}
	.nav-pills .nav-link{
		&::after{
			background-color: transparent !important;
			border-bottom: 4px solid $primary;
			position: absolute;
			left: 8px;
			content: "";
			width: 90%;
			border-radius: 0;
			bottom: -6px;
			height: revert;
		}
		&.text-danger::after{
			background-color: transparent !important;
			border-bottom: 4px solid $danger;
			position: absolute;
			left: 8px;
			content: "";
			width: 90%;
			border-radius: 0;
			bottom: -6px;
			height: revert;
		}
	}
	.nav-pills .nav-link.active{
		color: $blue;
		&::after{
		    background-color: transparent !important;
			border-bottom: 4px solid $blue;
			position: absolute;
			left: 8px;
			content: "";
			width: 90%;
			border-radius: 0;
			bottom: -6px;
			height: revert;
		}
	}
}


#address_form_modal{
	.modal-content{
		border-radius: 24px;
	}
}

.account-info{
	#v-pills-tabContent,#collapse-details{
		.card-body.card-custom{
				.form-control{
					padding: 0.75rem 1.5rem;
					font-weight: normal;
					font-size: 18px;
					line-height: 28px;
					color: #0C4365;
					opacity: 0.5;
					display: block;
					//width: 100%;
					height: 56px;
					background-color: #fff;
					border: 2px solid #e6e6e6;
					border-radius: 2.5rem;
					transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
					@include media-breakpoint-down(xs){
						padding: 0.75rem 1.5rem;
						height: 56px;
					}
			}
			
		}
		.card-pwd{
			.form-group{
				.input-group{
					input{
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					}
					button{
					padding: 0.5rem 1.2rem;
					}
				}
			}
		}
	}
	.dropdown-menu-mobile{
		top: -3px !important;
		//width: 312px;
	}
}

.btn-primary-white{
	color: #0C4365;
    background-color: white;
    border-color: #0C4365;
	height: 56px;
}

.btn-menu-mobile{
    background: #FFFFFF;
    border: 2px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 40px;
    //width: 312px;
    height: 56px;
	font-weight: 300;
	font-size: 18px;
    /* display: flex; */
	display: flex;
    justify-content: space-between;
    align-items: center;
	svg{
		    transform: rotate(90deg);
	}
}


#collapse-details{
	.card-header{
		h3{
		@include media-breakpoint-down(xs){
			font-size: 22px !important;
			}
		}
	}
}

.account-info{
	button .dropdown-toggle::after{
			content: none !important;
			display: none !important;
	}
	/*[data-zg-role="newsletter-preferences"]{
		.custom-control-label::before{
			border-radius: 20px;
			border-color: black;
			border: 2px solid black;
		}
		.custom-control-input:checked~.custom-control-label::before {
			background-color: black;
		}
	}*/
}

.hidden{
	display: none !important;
}

#v-pills-address, #collapse-address{
	.card-footer{
		    border-radius: 24px;
	}
}

#v-pills-details{
	.card-custom{
		dl{
			dt{

			}
		}
	}
	.card-header{
		h5{
			line-height: 32px; 
    		font-size: 22px;
		}
	}
}

.icon-orange{
		background-color: #FF9E38 !important;
		border: 1px solid #FF9E38 !important;
					.icon-home{
						background-image: url("../../images/icons/home-white.svg") !important;
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/home-white-mobile.svg") !important;
							background-repeat: no-repeat;
						}
					}
					.icon-config{
						background-image: url("../../images/icons/setting-white.svg") !important;
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/setting-white-mobile.svg") !important;
							background-repeat: no-repeat;
						}
					}
					.icon-order{
						background-image: url("../../images/icons/list-white.svg") !important;
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/list-white-mobile.svg") !important;
							background-repeat: no-repeat;
						}
					}
					.icon-fav{
						background-image: url("../../images/icons/heart-white.svg") !important;
						background-repeat: no-repeat;
						@include media-breakpoint-down(xs){
							background-image: url("../../images/icons/heart-white-mobile.svg") !important;
							background-repeat: no-repeat;
						}
					}
					.text-icon{
						color: #FF9E38 !important;
					}
}


.page_account{
	.select{
		width:90%;
		background-color: $white;
		border-color: $light;
		border-style: groove;
		border-radius: 40px;
		height: 50px;
	}
}
.filter {

	position:relative;

  .name {
    @extend .font-weight-bold;
    @extend .mb-4;
		@extend .mt-3;
    @extend .text-truncate;
    @extend .lead;
		@extend .font-weight-bold;
	font-size: 0,75rem!important;
	line-height: 0,938rem!important;
	font-weight: 300!important;
  }

  .content-filter {
    @extend .mb-3;
    @include media-breakpoint-up(sm) {
      //max-height: 140px;
      //overflow-y: auto;
    }

    a {

      line-height: 1em;
      padding-left: 0px;
      position: relative;
			
	   padding-top:0px;
	padding-bottom:0px;
	margin-left:0px;

      .name {
        @extend .font-weight-bold;
        text-transform: uppercase;
      }

      &:before, .option-image {
        border: 1px solid $secondary;
        display: inline-block;
        height: 1em;
        vertical-align: top;
        width: 1em;
      }

	  &.disabled {
        color: rgba(255,255,255,0.6) !important;
        text-decoration: none !important;
        cursor: not-allowed;
		display: none !important;
        &:before {
          border-color: rgba(255,255,255,0.6) !important;
        }
      }
    }

	/*
		a[data-zg-action="reset"]{
			position:absolute;
			top:0px;
			right:0px;
			width:auto;
			font-size:0.75rem;
			color:#000;
			border-bottom:1px solid #000;
			padding:2px 0px;
			&:before{
				display:none;
			}
			&:hover{
				text-decoration:none;
			}
		}
	*/
  }

}

.open .btns-apply-reset{
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: white;
	z-index: 9;
	padding: 2rem 0px;
	margin:0px;
	left:0px;
}

#filter-container {
  @include media-breakpoint-up(md) {
    
  }
  .filter-from-to{
	  .right-filter,.left-filter{
		padding:0;
	  	input{
			padding:0;
			border-radius: 0.4rem 0 0 0.4rem;
			border-color: #8E9FC3;
			border-width: 1px;
			height: 40px;
		}
		.input-group-text{
			padding:0;
			border-radius: 0 0.4rem 0.4rem 0;
			border-color: #8E9FC3;
			border-width: 1px;
			height: 40px;
		}
	  }
		
  }
}

#filter_blog_type{
  display: none;
}
canvas.threesixty-zoom-canvas{display:none !important;} 

@include media-breakpoint-down(md) {
	.product-gallery{
		padding:0;
	}
}



#productCarousel{
		@include media-breakpoint-up(md) {
			.carousel-item{
				display:block!important;
				float:none!important;
			}
		}
		.carousel-control-next, .carousel-control-prev{
			top:0;
		}
		.carousel-control-next-icon, .carousel-control-prev-icon{
			width: 5px;
   		height: 9px;
		}
		.carousel-control-prev{
			left:-37px;
			
		}
		.carousel-control-prev-icon{
			background-image:url("#{$img-path}/product/product-arrow-left.png");
		}
		.carousel-control-next{
			right:0px;
		}
		.carousel-control-next-icon{
			background-image:url("#{$img-path}/product/product-arrow-rightt.png");
		}
		.mobile-back{
			position: absolute;
			left: 15px;
			top: 10px;
			height: 30px;
			z-index: 9;
			img{
				margin-right:3px;
			}
		}
		.mobile-arrow{
			position:absolute;
			right:0;
			top:13px;
			height:30px;
			z-index:9;
			.carousel-control-next{
				height: 20px;
				width: 37px;
				display:inline-block;
				text-align:center;
				.arrow-right{			
				}
			}
			.carousel-control-prev{
				height: 20px;
				width: 37px;
				display:inline-block;
				text-align:center;
				position:relative;
				.arrow-left{
				}
				&:after{
					position:absolute;
					display:inline-block;
					content:"";
					right:0;
					top:0;
					height:20px;
					width:1px;
					opacity: 0.2;
					background-color: #000000;
				}
			}
		}
	}




.infoCnt{
	hr{
		border-top:1px solid #E6E6E6;
	}
	>nav{
		border-bottom:1px solid #E6E6E6;
	}
	div.list-unstyled{
		> ul{
			padding-inline-start: 15px;
    	padding-inline-end: 15px;
		}
	}
	div.detail{
		padding:1rem;
	}
	li.item > a{
		position: relative;
		width: 100%;
		float: left;
		svg{
			position:absolute;
			left: 17px;
			&.plus{
				display:none;
			}
			&.minus{
				display:block;
			}
		}
		&.collapsed{
			svg{
				&.plus{
				display:block;
				}
				&.minus{
					display:none;
				}
			}
		}
	}
}


.to-sell, .to-discount{
	margin-right:6px;
}

.to-sell{
	color:$black;
	font-weight: 400;
}

a.related, a.suggested{
	text-decoration:none;
}

.discounted {
	color: black!important;
}

.percent-discount{
	margin-left: 2px;
    text-align: center;
    padding: 0px 2px;
    background-color:black;
    color: #fff;
}

.selected-combination{
	font-size: 13px;
  text-align: right;
	.option-0{
		display:none;
	}
}

.btn-addtowishlist{
	background-color:#f3f7fa;
}

.product-sizes{
	a{
		font-size: 13px;
		padding:4px 10px 4px 10px;
		font-weight:normal;
    margin-right: 10px;
		&.active{
			border:1px solid #1a1a1a;
		}
	}
}

#zoomMain{
	cursor: pointer;
	//cursor: url('#{$img-path}/custom/zoom-in-18.svg'), auto;
	//cursor: image-set(url('#{$img-path}/custom/zoom-in-18.svg') 1x, url('#{$img-path}/custom/zoom-in-36.svg') 2x),auto;
	//cursor: -webkit-image-set(url('#{$img-path}/custom/zoom-in-18.svg') 1x, url('#{$img-path}/custom/zoom-in-36.svg') 2x),auto;
	display: block;
	justify-content: center;
	align-items: center;
	//background-color: #000000;
	//overflow: hidden;
	//height: 770px;
	//@media (min-width: 320px) and (max-width: 767px) {
	//overflow: visible;
		//height: 270px;
	//}
	.zoom-thumb-wrapper{
		position: absolute;
		left: 0px;
		//transform: translateX(-50%);
		bottom: -80px;
		@media (min-width: 320px) and (max-width: 720px) {
			bottom:-80px;
			width: 90%;
		}
		.zoom-thumb-container{
		display: flex;
		flex-direction: row;
		div{
			margin: auto 5px;
		}
		}

	}
	.zoom-thumb-container{
		display: none;
		.btn-thumb{
			@media (min-width: 320px) and (max-width: 767px) {
				margin: auto;
			}
			img{
				max-height: 60px;
			}
			.item-thumb{
				width:46px;
				cursor:pointer;
				opacity:0.3;
				@media (min-width: 320px) and (max-width: 767px) {
					img{
						width: 90%;
					}
				}
			}
			&.selected{
				.item-thumb{
					border:2px solid #fff;
					opacity:1;
				}
			}
		}
	}
}

.zoom-wrapper-main{
	display:none;
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index: 100;
	background:#fff;
	overflow:auto;
	padding-top:50px;
	img{
		width:100%;
		height:auto;
		padding:10px;
	}
	.zoom-close-main{
		position:absolute;
		top:16px;
		right:16px;
		display:block;
		width:24px;
		height:24px;
		cursor:pointer;
		svg{
			fill: #000;
			path{
				fill:#000;
			}
		}
	}
}

.zoom-wrapper{
	display:none;
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index: 100;
	background:#fff;
	.zoom-container{
		display:block;
		width:100%;
		height:100%;
		margin:0 auto;
		background-repeat:no-repeat;
		background-size:contain;
		background-color: #000000;
		background-position: center;
		.zoom-thumb-wrapper{
			position:absolute;
			top:45%;
			left:16px;
			width:46px;
			-webkit-transform: translateY(50%);
			transform: translateY(-50%);
			@media (min-width: 320px) and (max-width: 767px) {
				top:360px;
				width: 90%;
				left: 50%;
				transform: translateX(-50%)!important;
				transform: translateY(0);
				display: flex;
				justify-content: space-around;
			}
			.zoom-thumb-container{
				display: none;
				.btn-thumb{
					@media (min-width: 320px) and (max-width: 767px) {
						margin: auto;
					}
					.item-thumb{
						width:46px;
						cursor:pointer;
						opacity:0.3;
						@media (min-width: 320px) and (max-width: 767px) {
							img{
								width: 90%;
							}
						}
					}
					&.selected{
						.item-thumb{
							border:2px solid #fff;
							opacity:1;
						}
					}
				}
			}
		}
		.zoom-close{
			position:absolute;
			top:16px;
			right:16px;
			display:block;
			width:24px;
			height:24px;
			cursor:pointer;
			svg{
			fill: #ffffff;
			}
		}
	}
}


// Prodotto singolo



.band-limited {
	/* width: 1440px; */
	width: 100%;
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.feature-band {
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
	.feature-band-super {
		
	}

	.feature-band-intro {
		p {
			color: inherit;
		}
	}

	.feature-item {
		
		font-size: 14px;
		line-height: 1.2em;
		font-weight: 300;
		height: 100%;
		
		.feature-item-icon {
			max-width: 100%;
		}
		.feature-item {
			//color: #fff;
			font-size: 14px;
			line-height: 1.2em;
			font-weight: 300;
		}
		.feature-item-info {
			h6 {
				color: inherit;
				font-size: 17px;
				line-height: 22px;
				font-weight: 600;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
			}
			p {
				color: inherit;
				font-size: 17px;
				line-height: 28px;
				font-weight: 400;
				margin: 0;
				padding: 0;
				margin-top: 5px;
			}
		}
	}
}


.feature-img {
	h3 {
		padding: 40px 0 80px 0;
		margin: 0;
		font-size: 36px;
		line-height: 50px;
		font-weight: normal;
		//color: #003A70;
		text-align: center;
	}
}

.suggestedProducts{
	
	h4 {
		padding: 40px 0 40px 0;
		margin: 0;
		font-size: 36px;
		line-height: 50px;
		font-weight: 600;
		//color: #003A70;
	}
	p.text-center{
		color:#7B7B7B;
	}
}




.faqs {
	#faq-accordion {
		.card {
			background-color: #fff;
			display: block;
			padding: 0;
			outline: none;
			//-webkit-border-top-left-radius: 30px;
			//-webkit-border-bottom-right-radius: 30px;
			//-moz-border-radius-topleft: 30px;
			//-moz-border-radius-bottomright: 30px;
			//border-top-left-radius: 30px;
			//border-bottom-right-radius: 30px;
			//-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
			//-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
			//box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
			border: 1 px solid #e3e3e3;
			color: #003A70;
			background-color: #fff;
			display: block;
			text-align: left;
			margin: 1rem auto;
			.card-title {
				color: #003A70;
				font-size: 17px;
				line-height: 1.2em;
				font-weight: 600;
			}
			.card-subtitle {
				color: #003A70;
				font-size: 17px;
				line-height: 1.2em;
				font-weight: 400;
			}
			.card-header {
				border-bottom: none;
				background-color: transparent;
				display: block;
				outline: none;
				padding: 30px 60px 30px 30px;
				position: relative;
				&:not(.collapsed) .card-header-caret {
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
				}
				.card-header-caret {
					position: absolute;
					top: 20px;
					right: 30px;
					font-size: 24px;
					-webkit-transition: all .3s ease-in-out;
					-moz-transition: all .3s ease-in-out;
					-o-transition: all .3s ease-in-out;
					-ms-transition: all .3s ease-in-out;
					transition: all .3s ease-in-out;
				}

			}
			.card-content {
				.step-vignette {
				}
				.step-abstract {
					font-size: 17px;
					line-height: 1.5em;
					color: #7B7B7B;
					font-weight: 400;
				}
			}
		}
	}
}


.productImage-controls{
	position: absolute;
	top: 50%;
	margin-top: -52px;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 40px 10px;
	color: white;
		svg{
		width: 40px;
		height: 40px;
		fill: #FCAF16;
		opacity: 0.5;
	}
}
.productImage-before{
	left: 0px;
	@media (min-width: 320px) and (max-width: 767px) {
		left: 0px
	}
	&.prev-swipe-main{
		left:15px;
	}
}
.productImage-next{
	right: 0px;
	@media (min-width: 320px) and (max-width: 767px) {
		right: 0px
	}
	&.next-swipe-main{
		right:15px;
	}
}
.productImage-before2{
	left: 30px;
	@media (min-width: 320px) and (max-width: 767px) {
		left: 0px
	}
}
.productImage-next2{
	right: 30px;
	@media (min-width: 320px) and (max-width: 767px) {
		right: 0px
	}
}

.nav-product{
	box-sizing: border-box;
	padding:2.5rem 0px;

	@include media-breakpoint-down(md){
	  padding:1rem 0rem;
	  white-space: nowrap;
   	  overflow-x: auto;
      display:block !important;
		&::-webkit-scrollbar {
		display: none;
		}
	}
	
	a{
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		display: block;
		width: 22%;
		color: #F7D08E;
		border-bottom:6px solid #F7D08E;
		padding: 15px 0.5rem;
		&.active{
			color: $blue;
			border-bottom:6px solid $blue;
		}
		@include media-breakpoint-down(md){
	  		display: inline-block;
			margin-right: 1.5rem;
			width: auto;
  		}

	}

	
}

.characteristic-tablet{
	@media (min-width: 320px) and (max-width: 1180px) {
		display: flex;
		flex-direction: column;
	}
}


.tabletwidth{
	flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
	@media (min-width: 320px) and (max-width: 1180px) {
		max-width: 100%;
	}
}
.caracteristics-columns-template{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (min-width: 320px) and (max-width: 1180px) {
		flex: none;
		max-width: 100%;
		margin: auto;
	}

	.char-prod{
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	//letter-spacing: 0.15em;
	//text-transform: uppercase;
	border-bottom: 1px solid #D5D4E2;
	padding: 1rem 0px;
	margin:0px 10px;
	width: 45%;
	@media (min-width: 320px) and (max-width: 720px) {
	width: 95%;
	font-size: 14px;

	}
	}
}

.tab-content.tab-product{
	>.tab-pane{
		padding:2.5rem 0px;
		margin: 0px 1.666rem;
		@include media-breakpoint-down(md){
			padding: 1rem 0px;
			margin: 0px;
		}
	}
}

.page_product{
	background-color: $lighter-grey;
	@include media-breakpoint-down(md){
		h1.h2{font-size: 30px;}
	}
}

.product-details {

    .gallery-thumb {
        display: inline-block;
        width: 70px;
        border: 1px solid #999;
        cursor: pointer;
        opacity: 0.4;
        &.selected {
            opacity: 1;
        }
    }

	.btn-primary{
		border-radius: 0px;
		svg{
			margin-right: 20px;
		}
	}

	.badge-description{
		width: 50px;
		height: 50px;
		border-radius: 25px;
		border: 1px solid #000;
		text-align: center;
		line-height: 48px;
		font-size: 12px;
	}

    .product-information {
		@media (min-width: 320px) and (max-width: 720px) {
			margin-top: 20px;
		}
		del{
			font-size: 1rem;
			font-weight: 400;
			opacity: .5;
		}

	    .input-group{
			border: 2px solid $gray-200;
			border-radius: 40px;
		}

		.short{
			font-weight: 400;
			font-size: 30px;
		}

		

		.card{
			//background-color: #EEEEEE;
			//border-radius: 0px;
			border:0px;
			border:0px;

			.card-body{
				padding:15px 0px !important;
			}

			.btn-default{
				text-transform: none;
				padding:10px 40px;
				margin:0px 10px;
				@media (min-width: 320px) and (max-width: 1180px) {
					padding:10px 15px;
					margin:0px 5px;
				}
			}

			.btn-default:hover,
			.btn-default.active{
				background-color: white;
				box-shadow:4px 4px 10px rgba(0, 0, 0, 0.2);
			}

		}

       
        .table {
            margin-top: 20px;
            border-collapse: collapse;
            border-radius: 5px;
            border-style: hidden;
            box-shadow: 0 0 0 1px #ddd;
            text-align: center;
            .td {
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }
            h6 {
                font-size: 15px;
                line-height: 1.2em;
                font-weight: 500;
                text-transform: uppercase;
                color: #003A70;
                margin: 0;
                padding: 0;
            }
            p {
                font-size: 15px;
                line-height: 1.2em;
                font-weight: 400;
                color: #7B7B7B;
                margin: 0;
                padding: 0;
                margin-top: 15px;
            }
            .taglia-prodotto p {
                white-space: nowrap;
            }
            img.drops {
                width: 117px;
            }
        }
    }

    

    

    .faqs {
        #faq-accordion {
            .card {
                background-color: #fff;
                display: block;
                padding: 0;
                outline: none;
                -webkit-border-top-left-radius: 30px;
                -webkit-border-bottom-right-radius: 30px;
                -moz-border-radius-topleft: 30px;
                -moz-border-radius-bottomright: 30px;
                border-top-left-radius: 30px;
                border-bottom-right-radius: 30px;
                -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
                -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
                box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
                color: #003A70;
                text-align: left;
                margin: 1rem auto;
                .card-title {
                    color: #003A70;
                    font-size: 17px;
                    line-height: 1.2em;
                    font-weight: 600;
                }
                .card-subtitle {
                    color: #003A70;
                    font-size: 17px;
                    line-height: 1.2em;
                    font-weight: 400;
                }
                .card-header {
                    border-bottom: none;
                    background-color: transparent;
                    display: block;
                    outline: none;
                    padding: 30px 60px 30px 30px;
                    position: relative;
                    &:not(.collapsed) .card-header-caret {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                    .card-header-caret {
                        position: absolute;
                        top: 20px;
                        right: 30px;
                        font-size: 24px;
                        -webkit-transition: all .3s ease-in-out;
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -ms-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                    }

                }
                .card-content {
                    .step-vignette {
                    }
                    .step-abstract {
                        font-size: 17px;
                        line-height: 1.5em;
                        color: #7B7B7B;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .related {    
        background-color: #f5f5f5;
        min-height: 200px;
        .related-c {
            padding: 50px 20px;
            .owl-carousel {
                padding: 50px 20px;
                .owl-stage-outer {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    .owl-stage {
                        margin: 0 auto;
                    }
                }
            }
            text-align: center;
            a.product-card {
                display: block;
                padding: 20px 20px 30px 20px;
                background-color: #fff;
                text-align: center;
                min-height: 100%;
                -webkit-border-bottom-left-radius: 30px;
                -webkit-border-top-right-radius: 30px;
                -moz-border-radius-bottomleft: 30px;
                -moz-border-radius-topright: 30px;
                border-bottom-left-radius: 30px;
                border-top-right-radius: 30px;
                -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                
                .product-card-drops {
                    display: block;
                    width: 87px;
                    margin: 0 auto;
                    margin-top: 20px;
                    transition: transform 0.6s ease-in-out;
                }
    
                &:hover .product-card-drops {
                    transform: scale(1.1);
                }
    
                .product-card-img {
                    display: block;
                    width: 60%;
                    margin: 0 auto;
                    transition: transform 0.6s ease-in-out;
                }
    
                &:hover .product-card-img {
                    transform: scale(1.1);
                }
    
                .product-card-title {
                    font-size: 24px;
                    line-height: 1.25em;
                    font-weight: 400;
                    
                    margin-top: 15px;
                }
    
                .product-card-subtitle {
                    font-size: 17px;
                    line-height: 1.25em;
                    font-weight: 300;
                   
                    margin-top: 5px;
                    text-transform: uppercase;
                    min-height: 20px;
                }
    
                .product-card-link {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 20px;
                    color: #50BBD3;
                    text-transform: uppercase;
                }
            }
            .banner-tool {
                p {
                    margin: 0 auto;
                    margin-top: 30px;
                    font-size: 17px;
                    line-height: 28px;
                    font-weight: 400;
                    color: #003A70;
                    max-width: 1050px;
                }
                h3 {
                    font-size: 36px;
                    font-weight: 400;
                    line-height: 50px;
                    color: #003A70;
                }
            }
        }
    }
}

/*
.page_product{
	.gritter-item-wrapper.alert.alert-success{
		display: none !important;
	}
}
*/

.btn-cart{

	span.main-copy{
		-webkit-transition: opacity .35s ease-in-out;
        -moz-transition: opacity .35s ease-in-out;
        -ms-transition: opacity .35s ease-in-out;
        -o-transition: opacity .35s ease-in-out;
        transition: opacity .35s ease-in-out;
	}
	
	span.error-copy, span.adding-copy{
	position: absolute;
    top: 0px;
    margin: 0 auto;
    width: 419px;
    left: 0;
    padding: 1rem 2rem;
	opacity:0;
	-webkit-transition: opacity .35s ease-in-out;
	-moz-transition: opacity .35s ease-in-out;
	-ms-transition: opacity .35s ease-in-out;
	-o-transition: opacity .35s ease-in-out;
	transition: opacity .35s ease-in-out;
	}
	&:disabled{
		cursor:not-allowed;
		&:hover{
			span.main-copy{
				opacity: 0;
			}
			span.error-copy{
				opacity:1;
			}
		}
	}
}

.background-grey{
	background-color: #E2E2E2;
}

.characteristics-tables{
	.card{
		border-radius: 0.5rem;
		border:1px solid #d7e1ef;
		background-color:white;
		@include media-breakpoint-down(md){
			margin-bottom: 1rem !important;
		}
		.card-body{
			padding-bottom: 5px;
			@include media-breakpoint-down(md){
				padding-left:0.5rem;
				padding-right:0.5rem;
				padding-bottom: 5px;
			}
		}
		h5{
			margin-left: 15px;
			margin-bottom: 20px;
			i{
				color: #fcaf17;
				font-size: 26px;
				margin-right: 10px;
				position: relative;
				top: 4px;
			}
		}
		a:hover,
		a:focus{
			text-decoration: none;
		}
		
		a>h5{
			i.fa-chevron-down{
				float: right;
				color: black;
				transition: all .3s ease-in-out;
				font-size: 18px;
    			margin-top: 3px;
    			margin-right: 15px;
			}
		}
		a.active>h5 i.fa-chevron-down{
			transform: rotate(180deg);
		}
	}
}

.equipements-prod{
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	//border-bottom: 1px solid #D5D4E2;
	padding: 0.8rem 0px 0.1rem 0px;
	margin: 0 15px;
	//margin:0px 10px;
	//width: 100%;

	position: relative;
	padding-left: 25px;
	i{
		position: absolute;
		left: -25px;
		top: 1px;
	}

	@media (min-width: 320px) and (max-width: 720px) {
	//width: 95%;
	font-size: 14px;
	

	}
	label, label span{
		font-weight: 600;
	}
	.col-6{
		padding: 0px;
	}
	@include media-breakpoint-down(md){
		padding: 1rem 0px;
		font-size: 14px;
	}
}


.char-prod{
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	//border-bottom: 1px solid #D5D4E2;
	border-top:1px solid rgb(215, 225, 239);
	padding: 0.8rem 0px;
	margin: 0 15px;
	//margin:0px 10px;
	//width: 100%;
	@media (min-width: 320px) and (max-width: 720px) {
	//width: 95%;
	font-size: 14px;

	}
	label, label span{
		font-weight: 600;
	}
	.col-6,.col-4,.col-8{
		padding: 0px;
	}
	@include media-breakpoint-down(md){
		padding: 1rem 0px;
		font-size: 14px;
	}
}

.char-prod.first{
	padding: 0px;
}

.card.card_how_works{
	background: #FFFFFF;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	border-bottom: 0px;
	.card-header{
		border-bottom: 1px solid #D5D4E2;
		padding:2rem;
		font-weight: 400;
		font-size: 20px;
		line-height: 32px;
		max-height: 97px;
	}
	&.how_works_696590{
		.card-header{
			@include media-breakpoint-up(sm) {
				padding-top:1rem;
			}
		}
	}
	.card-body{
		@include media-breakpoint-up(sm) {
			min-height: 300px;
		}
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		padding: 2rem;
	}
}

#nav-instruction{
	@include media-breakpoint-down(md){
		.h1{
			font-size: 1.5rem;
		}
	}
}


.suggestedProducts{
	overflow: hidden;
	.category-products{
		margin-bottom: 30px;
	}
	.tns-outer{
		//width: 120%;
	}

	.tns-ovh{
		width: 100%;
	}
	.control li{
		cursor: pointer;
	}

	.control{
		position: relative;
		z-index: 98;
	}

	.control .next{
		//transform: rotate(180deg);
		margin-left: 20px;
	}
	.tns-nav{
		text-align: center;
		width: 100%;
		button{
			width: 16px;
			height: 16px;
			background: #D5D4E2;
			border-radius: 8px;
			border: 0px;
			padding: 0px;
			margin: 4px;
			&.tns-nav-active{
				background: #fcaf17;
			}
		}
	}
}

.product-info-bottom{
	height: 90vh;
	position: relative;
	overflow: hidden;
	img{
		width: 100%;
	}
	.info-top{
		background: linear-gradient(0deg, #322372 0%, rgba(0, 0, 0, 0) 100%);
		padding-top:50px;
		padding-bottom: 20px;
		@include media-breakpoint-down(md){
			padding-top: 100px;
			background: linear-gradient(0deg, #322372 70%, rgba(0, 0, 0, 0) 100%);
			padding-bottom: 0px;
		}

	}
	.info{
		//transform: rotate(-180deg);
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		color: white;
		text-align: center;
		@include media-breakpoint-down(md){
	  		position: relative;
			margin-top:-130px !important;
			margin: 0px -15px;
  		}
		
	}
	.info-description{
		background-color:$blue;
		padding-top:50px;
		padding-bottom: 50px;
	}
}


.suggestedProductsSliderFirst,
.suggestedProductsSlider  {
	display: flex;
}
@include media-breakpoint-down(md){
	.suggestedProductsSliderFirst,
	.suggestedProductsSlider  {
		.card{
			max-width: 90%;
			margin: 0px !important;
		}
	}
}


.letter-spacing_{
	&15{
		letter-spacing: 0.15rem;
	}
}


//Keywords & badge section
.badge-key-section{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 20px;
	.keybadgeContainer{
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		@media (min-width: 320px) and (max-width: 767px) {
			padding: 20px 0px;
		}
		&:nth-child(1){
			flex: 1.25;
			@media (min-width: 320px) and (max-width: 767px) {
				flex: 1;
			}
		}
		&:nth-child(2){
			flex: 0.85;
			@media (min-width: 320px) and (max-width: 767px) {
				flex: 1;
			}
		}
		.titleContainer{
			margin-bottom: 25px;
			padding: 10px 25px;
    		border-bottom: 6px solid #322372;
			color: #322372;
			text-transform: uppercase;
			text-align: center;
			font-weight: 400;
			font-size: 16px;
		}
	}
	.keywordsList{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		width: 100%;
		flex: 1.25;
		padding: 15px;
		p{
			padding: 10px;
			margin: 15px;
			border: solid 1px #222222;
			border-radius: 100px;
			@media (min-width: 320px) and (max-width: 767px) {
				margin: 5px;
			}
		}
	}
	.BadgeKey-blockPrice{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		border-top: solid 1px #d5d4e2;
		border-bottom: solid 1px #d5d4e2;
		p{
			padding: 19px;
			margin: 0;
			text-transform: uppercase;
			letter-spacing: .15em;
			&:nth-child(1){
				text-align: left;
				font-size: 16px;
				font-weight: 400;
			}
			&:nth-child(2){
				text-align: right;
				font-size: 16px;
				font-weight: 600;
				white-space: nowrap;
			}
		}
	}
	.badgeList{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		height: 100%;
		width: 100%;
		padding: 15px;
		justify-content: center;
		img{
			width: 100%;
			margin: 5px;
		}
	}
}
	@media (min-width: 320px) and (max-width: 1024px) {
		.badge-key-section{
			flex-direction: column;
		}
	}

	.btn-readMore{
		color: #322372;
		width: 100%;
		background-image: linear-gradient(0deg, #F8F8F8 70%, transparent 100%);
		cursor: pointer;
		transition: .5s;
	}
	.readMore{
		height: 120px;
		padding-top: 30px;
		margin-top: -100px;
	}
	.readLess{
		height: 0px;
		padding-top: 0px;
		margin-top: 0px;
	}
	.readClose{
		overflow: hidden;
		transition: .5s;
	}

	.to-discount{
		display: none!important;
	}




	.css-qshkzm {
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: currentcolor;
    vertical-align: middle;
    transform: rotate(180deg);
}

.back-to-result{
	font-weight: 500;
}

.product-information .section-ht .ht-info{

	padding: 1.6rem 2.4rem;
    border: 1px solid rgb(215, 225, 239);
	border-radius: 0.5rem;
	background-color: white;

	.logo-ht{
	position: relative;
	padding-left: 44px;
	color: #000;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: auto;
	margin-right: auto;
	i{
		position: absolute;
		font-size: 26px;
		left: 0px;
		top: 4px;
		color: #fcaf17;
	}
	svg{
		position: absolute;
		font-size: 30px;
		left: 22px;
		color: #fcaf17;
		transform: scale(4);
		top: 23px;
	}
	p{
		font-weight: bold;
		font-size: 16px;
		color: #000;
		margin-right: auto;
	}

}
}


.keybadgeContainer img{
	width: 50px;
	margin: 0px 5px;
}

#addCart{
	border-radius: 0.5rem;
}



.tooltip-wrap {
	position: relative;
	.modal.fade.show{
		align-items: center;
		display: flex!important;
		.modal-content{
			width: 400px;
			@include media-breakpoint-down(md){
				width: 96%;
			}
			margin: auto;
			background-color: #fff;
			border: 1px solid #d7e1ef;
			padding: 0;
			color: #000;
			//transition: 0.5s;
			border-radius: 10px; 
			font-size: 12px;
			h4{
				font-size: 16px;
			}
		}
	}
}
.tooltip-wrap .tooltip-content {
	display: none;
	position: absolute;
	bottom: 60px;
	left: 00%;
	margin-left: -175px;
	background-color: #fff;
	border: 1px solid #d7e1ef;
	padding: 20px;
	min-width: 400px;
	color: #000;
	transition: 0.5s;
	border-radius: 10px; 
	font-size: 12px;
		h4{
			font-size: 16px;
		}
	}
	.tooltip-content p{
		font-size: 12px;
		margin:0px;
		padding: 0px;
	}
.tooltip-wrap:hover .tooltip-content {
	display: block;
	z-index: 100;
	transition: 0.5s;
	}
	@media (min-width: 320px) and (max-width: 767px) {
		.tooltip-wrap .tooltip-content {
			display: none;
			position: absolute;
			bottom: 95%;
			left: -10px;
			background-color: #322372;
			padding: 20px;
			min-width: 270px;
			color: #ffffff;
			transition: 0.5s;
			border-radius: 15px; 
			}
	}


	.productGallery {
		.thumbnail{
			border: 1px solid #d7e1ef;
			border-radius: 5px;
			padding: 3px;
			background-color: white;
			&.active{
				border:1px solid black;
			}
		} 
		
	}

	.productGalleryContainer{
		.tns-controls{
			[data-controls="next"]{
				position: absolute;
				top: 4px;
    			right: 14px;
				border:0px;
				color: white;
				background-color: rgba(0,0,0,.7);
				padding: 19px 4px;
				height: calc(100% - 0.5rem);
			}

			[data-controls="prev"]{
				position: absolute;
				top: 4px;
    			left: 14px;
				border:0px;
				color: white;
				background-color: rgba(0,0,0,.7);
				padding: 19px 4px;
				height: calc(100% - 0.5rem);
			}
		}
	}


.mobile-bar{
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: white;
	width: 100%;
	z-index: 9999999;
	padding: 10px;
	box-shadow: 0 -4px 12px #0000001a;
	svg{
		position: relative;
		top: -2px;
	}
	.contatta-il-venditore{
		color: $blue;
		border-color: $blue;
	}
}

.iubenda-tp-btn.iubenda-cs-preferences-link{
	opacity: 0 !important;
}

.certificazioni-auto{
	border: solid 1px #d7e1ef; border-radius: .5rem; background-color:white;
	height: 100%;
}

.h-100{
	height: 100%;
}

.product-form{
	padding: 1.5rem;
    border: 1px solid #d7e1ef;
    border-radius: 0.5rem;
    background-color: #fff;
	button,
	textarea{
		border-radius: .25rem !important;
	}

	label.privacy{
		font-size: 12px;
		a{
			text-decoration: underline;
		}
	}

	#acceptPrivacy{
		float: left;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	#thanksContainer .btn{
		padding: 5px;
		position: absolute;
		right: 3px;
		font-size: 12px;
		top: 7px;
	}

	.alert{
		border-radius: .25rem !important;	
		margin-top: 15px;
	}
	
}

.mobile-bar #addCart{
	padding-right: 10px;
	padding-left:10px;
}
#prod-swipe-gallery-aa{
	width:100%;
	height:100%;
	overflow: hidden;
	top:0;

	>div, .zoom-thumb-container{
		display:block!important;
		width:100%;
		height:100%;
		overflow: hidden;
		white-space: nowrap;
		> div{
			display:inline-block;
			width:100%;
			height:100%;
			img{
				max-height: fit-content!important;

			}
		}
	}
}
.card-confirm, .payment_methods {
	.card-header {
	 border:none!important;
	 background-color:#fff;
	}
	.card-body {
		padding: 1rem!important;
	} 
}

.payment_methods {
	 border:none!important;
	}


.card-address { 
	.card-body, .card-header {
	 padding: 1.2rem;
	 height: auto;
	 //background-color: rgba(0,0,0,.03);
	 border: none!important;
	}
	
	.card-header {
	font-size: 11px;
	}

	.card-body {
	font-size: 14px;
	}
	.card-footer {
	background-color: transparent;
	border: none!important;
	}
}

.card-order { 
	 	border: none!important;
	.card-body, .card-header {
	 	padding: 1.25rem;
	 	border: none!important;
	}
	
	.card-header {
		font-size: 14px;
		background-color: rgba(0,0,0,.03);
	}

	.card-body {
		font-size: 14px;
		background-color: rgba(0,0,0,.03);
	}
}

.card-shipping, .payment_methods{
	border: none!important;
	.card-header {
	 background-color: #fff;
	 border: none!important;
	 .card-title {
	 font-size: 1.2rem;
	 }
	}
	.custom-control-label {
	font-size: 1rem;
	
	}
}
#breadcrumb-container{
	.breadcrumb-payment {
		text-align: center;
		color: #D9D9D9;
		margin-bottom: 0;
		list-style: none;
		background-color: #fff;
		margin-top:36px;
		position: relative;		
		padding-left: 0;
		.breadcrumb-payment-step {
			border-top: 3px solid #DEDEDE;
			position:relative;		
			text-align: left;;	
			h3,div{
				font-weight: 600;
				font-size: 11px;
				line-height: 16px;
				letter-spacing: -0.02em;
				color:$blue;
				padding-top:22px;
				//margin-left: -70%;
				width: 100%;
				text-align: center;
			}
			span{
				
				border:solid 1px #DEDEDE;
				border-radius:16px;
				height:32px;
				width:32px;
				background-color:#fff;
				color:$blue;
				position:absolute;
				left:-17px;
				top:-18px;
				line-height: 32px;
				text-align: center;
				font-weight: 600;
			}
			
			&.active {
			color: #000;
			//border-top: 3px solid #000;
				span{
					border:0;
					background-color:#F78E42;
					color:#FFF;
				}
				h3{
					color:#F78E42;
				}
			}
		}
	}
}

.summary-order {
	.card-header {
	font-size:11px;
	}
}

.login-checkout-tab {
	li {
	a:hover {
	 text-decoration:none;
	}
	}
}

.product-order-tab {
	font-size: 0.75rem;
}


.stepper-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
	.bread-title{
		font-size: 14px;
	}

}
.stepper-item {
  //position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
}




.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  width: 385px;
  height: 50px;
  margin-top: 5px;
  border-radius: 80px;
  background: #fff;
  margin-bottom: 6px;
  border: 1px solid #E6E6E6;
  @media (min-width: 320px) and (max-width: 1180px) {
  	width: 100%;
  }
}

.stepper-item.active {
  font-weight: bold;
  
}

.stepper-item.active .step-counter {
  background-color: $blue;
  color: #fff;
}

.stepper-item.complete .step-counter {
  background-color: $blue;
  color: #fff;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.rounded-top-sm{
	border-top-left-radius: 1rem ;
	border-top-right-radius: 1rem ;
}

.rounded-bottom-sm{
	border-bottom-left-radius: 1rem ;
	border-bottom-right-radius: 1rem ;
}

.product-line.mobile{
	[data-zg-role="product"]{
		div:nth-of-type(2n) {
  			background: #F2F6F5;
		}
	}
}

#sepabanktransferHeader{
	display: none !important;
}

.adyen-checkout__payment-method--sepadirectdebit{
	display: none !important;
}


.adyen-checkout__payment-method__radio{
	display: none !important;
}

.adyen-checkout__payment-method__radio--selected{
	display: none !important;
}

.padding-cart {
	.alert-danger {
		color: $white;
		background-color: #E359A1;
		border-color: #E359A1;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		padding: 35px 26px;
		text-align: left;

	}
}

#thanks-tab {
	.card {
		border:none!important;
		.card-header{
			background-color: #fff;
		}
	}

	.help-title {
		font-size: 1.6rem;
		@include media-breakpoint-down(sm){	
			font-size: 1rem;
		}

	}

	.card-header, .card-body {
		background-color: #f8f8f8!important;
	}

	.collapse-toggle, .collapse-toggle-upwards {
		&.collapsed:after {
			content: fa-content($fa-var-plus);
		}
	}
}

.page_orderview {
	.alert-success {
		color: $white;
		background-color: #9FD18B;
		border-color: #9FD18B;
		font-size: 1rem;
		font-weight: 300;
		padding: 30px;

	}
}

.bg-blue {
	background-color: $blue;
}

#page_dealer {
	.title {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 50px;
		letter-spacing: 0em;
		text-align: left;
	}

	.title-info {
		font-size: 1.5rem;
		font-weight: 400;
		text-align: left;
	}

	.description {
		font-size: 0.8rem;
		font-weight: 300;
		line-height: 25px;
		letter-spacing: 0em;
		text-align: left;
	}
}
.post-container {
	background-color: #FF9E38;
	border-radius: 52px;
	padding: 0px;

	img {
		border-radius: 52px;
	}
}

#blog-container {
	img {
		border-radius: 52px;
	}
}

.text-custom {
	color: #0C4365;
}

.text-mobiblog {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.text-mobititle {
	font-size: 1.375rem;
	line-height: 2rem;
}

.post-link-title {
	font-size: 1.75rem;
    line-height: 2.5rem;
}

.description-text {
	font-size: 1.125rem;
    line-height: 1.75rem;
}

.post-title {
	font-size: 2.5rem!important;
    line-height: 3rem!important;
	color: #007BC2;
	@include media-breakpoint-down(xs){
		font-size: 1.75rem!important;
	}
}

.discover-title {
	line-height: 46px!important;
	color: #007BC2;
	@include media-breakpoint-down(xs){
		font-size: 1.75rem!important;
	}
}


.form-control-blog {
	
}


.link-title-base {
	font-size: 1rem;
	font-weight: 400;
	text-align: left;
}

.link-description-base {
	font-size: 0.75rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;
	color: #686868;
}

.more_listing{
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 0.75rem;
	letter-spacing: 0em;
	text-align: right;
	margin-left: 10px;
	color: black;
	float: right;
	text-transform: uppercase;
	color: #686868;
	.icon {
		background-color: $orange;
		border-radius: 50%;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		margin-left: 5px;
		text-align: center;
		width: 30px;
		-o-box-shadow: 0 7px 20px rgba(245,195,75,.2);
		box-shadow: 0 7px 20px rgba(245,195,75,.2);
		transition: all .3s ease;
	}
	&:hover{
		text-decoration: none;
		.icon{
			background-color: $blue;
			color: $white;
		}
	}
}


.back-blog{
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 0.75rem;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 14px;
	color: black;
	float: left;
	text-transform: uppercase;
	color: #686868;
	.icon {
		background-color: $orange;
		border-radius: 50%;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		margin-right: 5px;
		text-align: center;
		width: 30px;
		-o-box-shadow: 0 7px 20px rgba(245,195,75,.2);
		box-shadow: 0 7px 20px rgba(245,195,75,.2);
		transition: all .3s ease;
	}
	&:hover{
		text-decoration: none;
		.icon{
			background-color: $blue;
			color: $white;
		}
	}
}

.date-base {
	font-size: 0.75rem;
	font-weight: 400;
	font-size: 0.75rem;
	letter-spacing: 0em;
	text-align: left;
	margin-left: 10px;
	position: relative;
	top: 2px;
	color: black;
	color: #686868;
	i{
		color: $orange;
		margin-right: 5px;
		font-size: 20px;
		position: relative;
		top: 2px;
	}
}

.blog-col {
	height: 100%;
    border-radius: 1.25rem;
    box-shadow: 3px 3px 9px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
	padding:10px;
	
	a:hover{
		text-decoration: none;
	}

	.card-footer{
		margin: 0px -10px -10px -10px;
	}

	.blog-img{
		aspect-ratio: 16 / 9;
		position: relative;
		overflow: hidden;
		border-radius: 1.25rem;	
		background-color: rgba(0,0,0,.03);
		img{
			position: absolute;
			transition: all .3s ease;
		}
	}

	&:hover .blog-img img {
    	transform: scale(1.1);
	}


	

}

.blog-tags{
	position: absolute;
	top:10px;
	left: 10px;
}

.btn-tag {
	color: $white;
    background-color: $orange;
    border-color: $orange;
	text-transform: uppercase;
	font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.5px;
	line-height: 10px;
	/*
	&:hover {
		color: $white;
		background-color: $blue;
		border-color: $blue;
	}*/
}




	.post-header {
		.date{
			font-size: .75rem;
			font-weight: 400;
			font-size: .75rem;
			letter-spacing: 0;
			text-align: left;
			position: relative;
			top: 2px;
			color: #686868;
			i{
				color: $orange;
				margin-right: 5px;
				margin-left: 2px;
				font-size: 20px;
				position: relative;
				top: 2px;
			}
		}
		
	}

	.img-border{
			border-radius: 1.25rem;
    		box-shadow: 3px 3px 9px 0 rgba(0,0,0,.1);
    		background-color: #fff;
    		padding: 10px;
		}

.page_home,
.content_page_blog{
	#site_main{
		padding-top: 0px;
	}
	#site_header.header,#site_header.header.header-regular, #site_header.header.menuOpen{
		background-color: rgba(44,54,114,0);
		background: linear-gradient(180deg,rgba(21,23,48,.6) 10.13%,rgba(21,23,48,0) 100%);
	}
}	



.blog_header{
	overflow: hidden;
	position: relative;
	background: rgb(93,99,165);
	background: linear-gradient(90deg, rgba(93,99,165,1) 0%, rgba(44,54,114,1) 50%, rgba(21,23,48,1) 100%);
	padding-top: 8.5rem !important;
	.background-blue-opacity{
		background-color: #2c3672;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100vh;
		opacity: 0.6;
	}
	img{
		position: absolute;
		top: 0px;
		left: 0px;
		opacity: 0.5;
		left: 0px;
		transform: translate(0,0);
		pointer-events: none;
	}
	h1{
		color: #fff;
		font-weight: 500;
		font-size: 35px;
		line-height: 50px;
		color: $orange;
	}
}



#bl-container{
	.pagination-controls{
		display: flex; 
		flex-direction: column; 
		align-items:center; 
		justify-content: center;
	}
	.pagination{
		padding: 0px;
		margin: auto 10px;
		li.disabled{
			pointer-events: auto! important;
     		cursor: not-allowed! important;
			opacity: .5;
		}
		li.arrow-right,li.arrow-left{
			position:relative;
			padding: 0 ;
    		text-align: center;
			width:50px;
			a{
				position:absolute;
				top:12px;
				display: block;
				text-align: center;
				width: 100%;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.page-item{
			padding:8px 20px;
			cursor: pointer;
			border:solid 1px #686868;
			border-radius: 5px;
			color: #686868;
			margin:0px 5px;
			
			&.active{
				background-color: #fcaf17;
				color: #fff;
			}
		}
	}
	
}


.filters-blog{
	background-color: #fdcf74;
	.form-control{
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.125);
		border-radius: 8px !important;
		color: #5f6973;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		height: 50px;
		line-height: 16px;
		letter-spacing: 0;
		margin-bottom: 0px;
		padding-left: 20px;
	}
	select.form-control{
		-webkit-appearance: none;
    	-moz-appearance: none;
    	appearance: none;
		
	}

	.form-select{
		position: relative;
		&:after{
			font-family: "Font Awesome 5 Free";
    		font-weight: 900;
			content: fa-content($fa-var-arrow-down);
			font-size: 1rem;
			top: 13px;
			right: 30px;
			position: absolute;
			color: #444;
		}
	}

	.btn-primary{
		padding:16px;
	}
}

.related-blog{
	background-color: #e0e3e4;
}

.quote_section{
	position: relative;
	sup{
		position: absolute;
		font-size: 2rem !important;
		top: 5px;
		@include media-breakpoint-down(md) {
        	font-size: 1.5rem !important;
			top: 4px;
   	 	}
	}
	p.lead{
		font-weight:bold;
		font-size: 2rem !important;
		padding-left: 50px;
		@include media-breakpoint-down(md) {
        	font-size: 1.5rem !important;
			line-height: 1.8rem;
			padding-left: 35px;
   	 	}
	}
	.author{
		margin-left: 35px;
	}
}


.blogCarousel{
	.carousel-control-next, .carousel-control-prev {
		background-color: transparent;
		color: black;
		margin-top: -3.6rem;
		top: 0px;
		width: auto;
		height: 30px;
	}

	.carousel-control-prev{
		left: auto;
		right: 40px;
	}
}


.carsCarousel{
	.carousel-control-next, .carousel-control-prev {
		background-color: transparent;
		color: black;
		margin-top: -3.6rem;
		top: 0px;
		width: auto;
		height: 30px;
	}

	.carousel-control-prev{
		left: auto;
		right: 40px;
	}

	.product-col{
		padding:0px;
		.category-products{
			padding:0px;
		}
	}

	.suggestedProductsSlider{
		height: 100%;
	}

}

.cars_section{
	@media (max-width: 768px) {
		.carousel-inner .carousel-item > div {
			display: none;
		}
		.carousel-inner .carousel-item > div:first-child {
			display: block;
		}
	}

	.carousel-inner .carousel-item.active,
	.carousel-inner .carousel-item-next,
	.carousel-inner .carousel-item-prev {
		display: flex;
	}

	/* display 3 */
	@media (min-width: 768px) {
		
		.carousel-inner .carousel-item-right.active,
		.carousel-inner .carousel-item-next {
		transform: translateX(33.333%);
		}
		
		.carousel-inner .carousel-item-left.active, 
		.carousel-inner .carousel-item-prev {
		transform: translateX(-33.333%);
		}
	}

	.carousel-inner .carousel-item-right,
	.carousel-inner .carousel-item-left{ 
		transform: translateX(0);
	}
}








.herobanner-hp{
	.banner-caption{
		padding: 20px;
		position: relative;
		margin-top:90%;
		color:#ffffff;
		text-align: left;
		.carousel-action a{
			padding: 8px;
			margin-top: 16px;
			height: 45px;
			.btn{
				padding:8px 16px;
			}
		}
		.collapse.show{
			button{
				display: flex;
				justify-content: space-between;
				svg{
					
				}
			}
		}
		.btn-link{
			padding: 12px 0px;
			path{
				stroke:#000000;
			}
		}
		.filter{
			.content-filter{
				padding: 6px 0;
			}
		}
	}
	a.btn-outline-white, a.btn-outline-white:hover{
		color: #ffffff;
		margin-bottom: 2px;
		&.active{
			background-color: #ffffff;
			color:#000000;
			svg{transform:rotate(180deg);}
			path{stroke:#000000;}
		}
	}

	.btn-link{
		padding: 0;
	}

	.gradientBkg{
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		background: linear-gradient(0deg, #322372 10.13%, rgba(50, 35, 114, 0) 100%);;
	}
}

.carousel-hp{
	min-height:398px;
	.carousel-item{
		min-height:398px;
		background-color: #2c3773;
		@include media-breakpoint-up(xl) {
			min-height: 440px;
		}
		@include media-breakpoint-down(xl) {
			.max-height-description{
				line-height:24px;
				font-size:1.15rem;
			}
		}
	}
	.pt-slide-home{
		@include media-breakpoint-down(md) {
			padding-top:150px;
			.max-height-description{
				//max-height:91px ;
				overflow: hidden;
    			text-overflow: ellipsis;
				position: relative;
				line-height:24px;
			}

		}
	}
	.filters-home{
		position: absolute;
		z-index: 95;
		@include media-breakpoint-up(md) {
			bottom:12%;
			top:inherit;
			left:0;
			padding-left:15px;
			padding-right:15px;
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			bottom:15%;
			//top:30%;
			//bottom: inherit;
			left:calc(5% + .938rem);
			right:calc(5% + .938rem);
		}
	}

	#products-container {
		@media (min-width: 768px) and (max-width: 991px) {
			width: 95%;
		}
			.row{
				justify-content:space-between;
				>div{background-color: transparent;}
				.carousel-action{
					width: auto;
					height: auto;
					padding: 0;
					a{
						padding:9px;
						margin-top: 3px;
					}
				}
			}
			#filter-container{
				.filter{
					@include media-breakpoint-up(md) {
						min-width: 325px;
						}
					@include media-breakpoint-up(lg) {
						min-width: 25%;
					}
				}
				.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
					background-color: #fff;
					svg{
						transform:rotate(180deg);
					}
				}
				.dropdown{
					.btn{
						padding-right:0;
						&::after{
							display: none;
						}
						svg.arrowDown{
							position: relative;
							text-align: right;
							margin-right: 2.188rem;
    						margin-left: 1rem;
						}
					}
					&.show{
						.btn{
							svg.arrowDown{
								path{
									stroke:black;
								}
							}
						}
					}
				}
				.dropdown-menu.show{
					background-color: #fff;
					border-radius: 20px;
					padding: 10px;
				}
				.filter .content-filter, .filter .content-filter a{
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-left: 5px;
    				margin-right: 5px;
				}
				.filter .content-filter{
					max-height: 80px;
					overflow-y: scroll;
				}
			}
			#filter-container{
				a.btn-filter-mobile{
					&.active{
						color: $white;
						&::after{
							font-size: 14px;
							padding-left: 40px;
							letter-spacing: -0.02em;
							cursor:pointer;
							box-sizing: border-box;
							text-align: center;
							color: $white;
							text-decoration: none;
							content: url(../../images/icons/arrow-up-white.svg);
							border: none;
						}
					}
					&::after{
						font-size: 14px;
						padding-left: 40px;
						letter-spacing: -0.02em;
						cursor:pointer;
						box-sizing: border-box;
						text-align: center;
						color: $white;
						text-decoration: none;
						content: url(../../images/icons/arrow-down-white.svg);
						border: none;
					}
					&:hover{
						color: $white;
					}
				}
				.collapse-toggle:after{
					content: none;
					display: none;
				}
			}
		}

		#filter-container{
			@media (min-width: 768px) and (max-width: 991px) {
				display: flex;
				flex-wrap: wrap;
			}
			@include media-breakpoint-up(lg){
				display: flex;
			}
		}




	.carousel-caption{
		
		
		bottom: 0 !important;
		height: 100%;

		@include media-breakpoint-down(md) {
			padding-bottom: 100px;
		}

		h1, .h1{
			font-size:2rem;
			    line-height: 42px;
		}

		h3, .h3{
			color: $orange;
			font-size: 1.25rem;	
			@include media-breakpoint-up(sm) {
				font-size: 1.625rem;
			}
		}

		p{
			font-size: 1rem;
			font-weight: 300;
			@include media-breakpoint-up(sm) {
				font-size: 1.25rem;
			}
		}

		@include media-breakpoint-up(sm) {
			h1, .h1{
				font-size:3rem;
				    line-height: 42px;
			}
		}
		
		@include media-breakpoint-up(md) {
			bottom:42%;
			top:inherit;
			left:0;
			padding-left:40px;
			padding-right:40px;
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			bottom:30%;
			//top:30%;
			//bottom: inherit;
			left:calc(5% + .938rem);
			right:calc(5% + .938rem);
		}
	}
	.gradientBkg{
		width: 100%;
		height: 100%;
		position: absolute;
		display: block;
		bottom: 0;
		z-index: 99;
		//background: linear-gradient(0deg, #322372 10.13%, rgba(50, 35, 114, 0) 100%);
		@media (min-width: 768px) and (max-width: 991px) {
			height: 100%;
		}
	}
	.carousel-indicators{
		bottom:0px;
	}
	.carousel-control-prev,
	.carousel-control-next {
		background-color: #2c3672;
		width: 50px;
		height: 50px;
		margin-top: -25px;
	}
	.carousel-inner{
		.carousel-item{
			display:block;
			/*picture{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				align-content: center;
				align-items: center;
				justify-content: center;
				text-align: center;
				img{
					min-height:100%;
					width:auto;
					@include media-breakpoint-down(lg) {
						height:auto;
						min-width:100%;
					}
				}
			}*/
			/*img{
				width:100%;
			}*/
			
		}
	}
	@media (min-width: 768px) and (max-width: 991px) {
		.carousel-inner{
			min-height: 600px;
			.carousel-item{
				height: 100%;
				
				picture{
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						height: 600px;
						width: auto!important;
					}
				}
			}
		}
	}
}

.component-box-cerca{
	//margin-top:3rem;
	margin-bottom:3rem;
	.carousel-indicators{
		right:43%;
	}
	.wrapper{
		//margin-left: 1.5%;
		border-radius:20px;
		border:1px solid rgb(0 0 0 / 20%);
		background-color: white;
		@include media-breakpoint-up(sm) {
			box-shadow: 3px 3px 10px rgb(0 0 0 / 20%);
			-moz-box-shadow: 3px 3px 10px rgb(0 0 0 / 20%);
			-webkit-box-shadow: 3px 3px 10px rgb(0 0 0 / 20%);
			border:none;
		}
	}
	.border{
		border-radius: 20px 20px 0 0;
		padding: 30px;
	}
	.text{
		padding: 30px;
	}
	@include media-breakpoint-up(sm) {
		padding-top: 3rem;
		//margin-top:6rem;
		margin-bottom:0;
		max-width: 100%;
		overflow: auto;
		white-space: break-spaces;
		.wrapper{
			//width: 480px;
			height: 100%;
		}
		.border{
			width: 33%;
			border-radius: 20px 0 0 20px;
			padding: 20px;
		}
		.text{
			padding: 25px 25px 30px 25px;
		}
	}
	@include media-breakpoint-up(lg) {
		max-width: inherit;
		overflow: hidden;
		.wrapper{
			//width: 31%;
		}
	}
}

.component-textImage{
	@include media-breakpoint-up(lg) {
		.textCnt{
			padding: 1.5vw;
		}
	}
}

.suggestedProductsSlider .product-preview{
	display: flex !important;
	justify-content: center !important;
	flex-shrink: 0;
	width: 100%;
}

.contentHiW{
	width: 290px;
	@media (min-width: 320px) and (max-width: 767px) {
		width: 100%;
	}
}

.component-product{
	@include media-breakpoint-up(md) {
		max-width: 100%;
		overflow: auto;
	}
	@include media-breakpoint-up(sm) {
		margin-top:6rem;
		margin-bottom:0;
	}
	.product-preview.box{
		width: 35vw;
		padding:0 15px;
		article{
			box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
				-moz-box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			img{
				border-radius: 20px 20px 0 0;
			}
			.product-options{
				padding:30px;
			}
		}
	}
}

$finalHeight: 35vw;
$finalWidth: 3 * $finalHeight;
$scrollBarHeight: 3px;

::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}


div.box {
  box-sizing: border-box;
}

.horizontal-scroll-wrapper {
  position: relative;
  display: block;
  margin-left: 5px;
  width: calc(#{$finalHeight} + #{$scrollBarHeight});
  max-height: $finalWidth;
  padding-top: $scrollBarHeight;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-$finalHeight);
  transform-origin: right top;
  & > div {
    display: block;
    padding: 5px;
    transform: rotate(90deg);
    transform-origin: right top;
  }
}

.squares {
  padding: $finalHeight 0 0 0;
  & > div {
    width: $finalHeight;
    height: $finalHeight;
    margin: 10px 0;
  }
}


#carouselwithbackground-696431{
	.carousel-indicators{
		bottom: -8%;
		@include media-breakpoint-up(md){
			bottom: 15%;
		}
	}
	.carousel-caption{
		a:hover{
			color: #ddd;

		}
	}
}


.filters-home{

	#products-container{
		border-radius: 4px;
		background: #FFF;
		box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12);
		padding: 0px 1.5rem;
		@include media-breakpoint-down(md) {
			padding: 0px 1rem;
		}
	}
	

	margin-top: -45px;
    position: relative;
	z-index: 95;

	.dropdown{

		.dropdown-toggle::after{
			display: none;
		}

		.label-dropdown{
			padding: 2px 8px;
			width: calc(100% - 40px);
		}


		

		.btn-secondary{
			-webkit-box-align: center;
			align-items: center;
			cursor: default;
			display: flex;
			flex-wrap: wrap;
			-webkit-box-pack: justify;
			justify-content: space-between;
			min-height: 40px;
			position: relative;
			transition: all 100ms ease 0s;
			background-color: rgb(255, 255, 255);
			border-color: rgb(204, 204, 204);
			border-radius: 4px;
			border-style: solid;
			border-width: 1px;
			box-sizing: border-box;
			outline: 0px !important;
			color: rgb(128, 128, 128);
			text-transform: capitalize;
    		padding: 0px;

			&:hover{
				    border-color: rgb(179, 179, 179);
			}

			.icon-arrow-container{
				.indicatorSeparator{
					-webkit-align-self: stretch;
					-ms-flex-item-align: stretch;
					align-self: stretch;
					width: 1px;
					background-color: hsl(0, 0%, 80%);
					margin-top: 5px;
					box-sizing: border-box;
					display: block;
					height: 22px;
					float: left;
				}
			}


			.icon-arrow{
				display: flex;
				-webkit-transition: color 150ms;
				transition: color 150ms;
				color: hsl(0, 0%, 80%);
				padding: 8px;
				box-sizing: border-box;

				svg{
					display: inline-block;
					line-height: 1em;
					flex-shrink: 0;
					color: rgb(72, 88, 133);
					vertical-align: middle;
					width: 1rem;
					height: 1rem;
					transform: rotate(0deg);
					transition: transform 0.2s ease-in 0s;
				}
			}
			
		}

		.dropdown-menu{
			background-color: white;
			max-height: 300px;
			overflow: scroll;
			border-radius: 4px;
			border-color: rgb(204, 204, 204);
			border-radius: 4px;
			border-style: solid;
			border-width: 1px;
			box-sizing: border-box;
			
			.content-filter{
				margin-bottom: 0px;
			}

			a{
				display: block;
				padding: 10px;
				color: rgb(128, 128, 128);
				text-transform: capitalize;
				&:hover{
					background-color: rgba(44, 54, 114, 0.3);
					color: white;
					text-decoration: none;
				}
			}
		}


		&.show{
			.btn-secondary{
				.icon-arrow{
					svg{
						transform: rotate(180deg);
					}
				}
			}
		}
	
	}

	.btn-primary{
		padding: 11px;
    	font-weight: 500;
    	letter-spacing: 1px;
    	text-transform: none;
	}


	#filter_char_1200.filter .content-filter a.disabled{
		color: grey !important;
    	cursor: inherit;
    	display: block !important;
	}

	


}


.component-textImage{
	.box{
		background-color: $blue;
		color: white;
		position: absolute;
		bottom: 10px;
		left: 25px;
		padding: 2rem;
		font-weight: 700;
		
		@include media-breakpoint-up(md) {
			border-end-start-radius: 0.7rem;
		}
		@include media-breakpoint-down(md) {
			position: relative;
			left: auto;
			margin-bottom: 1rem;
		}
		&.box-right{
			left:auto;
			right: 25px;
			@include media-breakpoint-up(md) {
				border-end-start-radius: 0;
				border-end-end-radius: 0.7rem;
			}
			@include media-breakpoint-down(md) {
				right: auto;
			}
		}
		.color-orange{
			color: orange;
		}
		.font-big{
			font-size:5em;
			line-height: 80px;
		}
		.font-medium{
			font-size:3em;
			line-height: 50px;
		}
		.modelli{
			letter-spacing: 2px;
		}
	}
}

	
.list-dealer-header{
	background-color:$blue;
	color: #fff;
	padding-bottom: 30px;
	h1{
		padding-top:40px;
		padding-bottom:10px;
	}
	@include media-breakpoint-up(lg) {		
		padding-bottom: 60px;
		h1{
			padding-top:90px;
			padding-bottom:30px;
		}
	}
}
.list-dealer-filters{
	padding-bottom: 30px;
	@include media-breakpoint-up(lg) {		
		padding-bottom: 60px;
	}
}
#dealers-container{
	padding-bottom:50px;
	.custom-select{
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: 93% 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='19' height='9' viewBox='0 0 19 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.427734 0.732422L9.49201 7.87528L18.5563 0.732422' stroke='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
		height: auto;
		border-color: #ffffff;
		color:#ffffff;
		font-weight: 400;
		text-transform: capitalize;
		option{
			color:#000;
		}
	}
	.counter-dealer{
		font-size: 14px;
    	font-weight: bold;
		span{
			margin-right:10px
		}
	}
	.dealer-block-list a{
		border-radius: 20px;
		box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
		padding:0;
		overflow: hidden;
		margin-bottom:30px;
		display:block;
		&:hover{
			text-decoration:none;
		}
		h4{
			padding: 20px 15px 0;
		}
		p{
			padding: 0 15px 10px;
		}
	
	}
	
	
	.pagination-controls{
		display: flex; 
		flex-direction: column; 
		align-items:center; 
		justify-content: center;
	}
	.pagination{
		padding: 0px;
		margin: auto 10px;

		@include media-breakpoint-down(md) {
			display: none;
  		}

		li.disabled{
			pointer-events: auto! important;
     		cursor: not-allowed! important;
			opacity: .5;
		}
		li.arrow-right,li.arrow-left{
			position:relative;
			padding: 0 ;
    		text-align: center;
			width:50px;
			@include media-breakpoint-down(md) {
				height: 50px;
  			}
			a{
				position:absolute;
				top:12px;
				display: block;
				text-align: center;
				width: 100%;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.page-item{
			padding:8px 20px;
			cursor: pointer;
			border:solid 1px #686868;
			border-radius: 5px;
			color: #686868;
			margin:0px 5px;
			
			&.active{
				background-color: #fcaf17;
				color: #fff;
			}
		}
	}



	#dealer-filter-container{
		background:$blue;
		select.form-control{
			border:solid 1px #fff;
			color:#fff;
			background:$blue;
			padding: 0.75rem 1.5rem;
			height:56px;
			/*-webkit-appearance: none !important;
			-moz-appearance: none !important;
			appearance: none !important;*/
			 .caret {
				width: 10px;
				height: 10px;
				border: none;
				background-color: green;
			}
		}
	}
}

.tabletDealer{
	@media (min-width: 320px) and (max-width: 1180px) {
		display: flex;
		flex-direction: column;
		div{
			min-width: 100%;
		}
		.row{
			div{
				min-width: auto;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	@media (min-width: 320px) and (max-width: 767px) {
		div{
			min-width: auto;
		}
	}
}

.bannerTablet{
	@media (min-width: 768px) and (max-width: 1180px) {
		img:nth-child(1){
			display: block!important;
		}
		img:nth-child(2){
			display: none!important;
		}
	}
}

#page_dealer{
	.ranking-info{
		display: none;
	}
}


#dealer-list-container{
	.dealer-col {
	height: 100%;
    border-radius: 1.25rem;
    box-shadow: 3px 3px 9px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
	padding:10px;
	
	a:hover{
		text-decoration: none;
	}

	.card-footer{
		margin: 0px -10px -10px -10px;
	}

	.dealer-img{
		aspect-ratio: 16 / 5;
		position: relative;
		overflow: hidden;
		border-radius: 1.25rem;	
		background-color: rgba(0,0,0,.03);
		img{
			position: absolute;
			transition: all .3s ease;
		}
	}

	&:hover .dealer-img img {
    	transform: scale(1.1);
	}

}
}

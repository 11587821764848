.page_confirm, .page_cart, .page_orderview {
  text-align:center;
  .card-content{
    padding:16px;
    box-sizing: border-box;   
    font-size:14px;
    line-height: 24px;
  }
  .modal-content{
    border-radius: 0;
  }
  .btn-edit-address{
    right: 3px;
    top: 16px;
    b{
      display:none;
    }
  }
  .svg-select{
    position:absolute;
    top: 22px;
    right: 36px;
  }
  .btn-new-address{
    a{
      font-size:16px;
      font-weight:500;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      padding:0;
      width:100%;
      height:48px;
      line-height:44px;
    }
  }
  [data-zg-role="select-shipping-method"],.payment-custom{
   // display:table;
   // margin:27px -4px 0;
    padding-left: 25px;
    width: 100%;
    >div,>li{
      display: table-cell;
      padding:4px;
      @include media-breakpoint-down(sm) {
        display: table-row;
      }
      .custom-radio{
        padding:1px;
        label{
        
        }
      }
    }
  }
  .product-item{
    box-sizing: border-box;
    margin-bottom:10px;
    >.row{
      position: relative;
      align-items: center;
      .product-confirm{
        margin-bottom:10px;
        position:relative; 
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        p{
          font-size:14px;
        }          
      }

      .price{
        font-size:16px;
      }
        
       
    }
  }
  .custom-detail{
    font-size: 1rem;
    line-height: 19.2px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .total .custom-detail{ border-bottom:0;}
  
  @include media-breakpoint-up(sm) {
    text-align:left;
    h1{
      margin-top:50px;
    }
    .btn-edit-address{
      border:solid 1px #D5D4E2;
      text-transform: uppercase;
      box-sizing: border-box;
      border-radius: 200px;
      padding: 8px;    
      //styleName: button;
      font-size: 0.7rem;
      font-weight: 300;
      line-height: 19px;

      
      b{
        display:inline;
        color:#D5D4E2;
      }
      svg{
        display:none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .product-item{
    
    >.row{
       &::after{          
          right:32%;
        }
       
    }
  }
      h1{margin-bottom:48px;}
      #myTabContent{
      .btn-orange{
        width:100%;
      }
    }
  }
}

.title-tab {
font-size: 1.625rem;
font-weight: 400;
line-height: 54px;
letter-spacing: 0em;
text-align: left;

}

.title-indi {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
}

.container-checkout {
  background-color: #E2E2E2;
}

.container-confirm {
  background-color: #E2E2E2;
}

.element-box {
  box-shadow: 3px 3px #E2E2E2;
  border-radius: 25px;
  //max-height: 800px;
}

.desc-payment {
  //styleName: subheader: card;
font-size: 1rem;
font-weight: 400;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
padding: 0px;

}

.payment-custom {
//font-size: 35px;
//font-weight: 600;
//line-height: 50px;
//letter-spacing: 0em;
//text-align: left;
.custom-radio-label{
  margin:9px 0px 0px 19px;
  &:hover{
    text-decoration: none;
  }
}

}


.form_confirm {

  .form-hidden{
    display: none;
  }
  #form-trade-in-vehicle{
    background-color:$blue;
  }

	/* VISUALLY HIDE THE ORIGINAL CHECKBOX/RADIOBUTTON */
	.custom-check input[type=checkbox],
	.custom-radio input[type=radio] {
		width: 1px;
		height: 1px;
		padding: 0;
		border: 0;
		margin: -1px;
		clip: rect(0,0,0,0);
		overflow: hidden;
		position: absolute;
	}

	.custom-check-elem, .custom-radio-elem,
	.custom-check-label, .custom-radio-label {
		cursor: pointer;
		vertical-align: middle;
	}

	/* CUSTOM CHECKBOX */
	.custom-check-elem, 
	.custom-radio-elem { 
		display: inline-block;
		position: relative;
		margin-right: 19px;
		border: 1px solid $black;
		width: 20px;
		height: 20px;
	}
	/* CUSTOM RADIOBUTTON */
	.custom-radio-elem { 
		-webkit-border-radius: 999px;
		-moz-border-radius: 999px;
		border-radius: 999px;
	}

	/* CUSTOM CHECKBOX/RADIOBUTTON CHECK */
	.custom-check-elem:before,
	.custom-radio-elem:before {
		content: "\00d7";
		display: none;
		position: absolute;
		top: 0px;
		left: 1px;
		width: 100%;
		height: 100%;
		font-family: verdana, arial, sans-serif;
		font-size: 1.0em;
		line-height: 1.0em;
		font-weight: bold;
		text-align: center;
	}
	/* CUSTOM RADIOBUTTON CHECK */
	.custom-radio-elem:before {
		content: "\2022";
		font-size: 1rem;
		line-height: 1rem;
	}

	/* PSEUDO ELMENT CONTENT FOR CHECKED STATE */
	.custom-check-input:checked + .custom-check-elem:before,
	.custom-radio-input:checked + .custom-radio-elem:before { display: block; }
	/* IE8: needs js-fallback for :checked, don't mix selectors! */
	.custom-check-input.checked + .custom-check-elem:before,
	.custom-radio-input.checked + .custom-radio-elem:before { display: block; }

}

.thankyou-ordercode {
  font-size: 23px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

}

.payment-content{
/* VISUALLY HIDE THE ORIGINAL CHECKBOX/RADIOBUTTON */
	.custom-check input[type=checkbox],
	.custom-radio input[type=radio] {
		width: 1px;
		height: 1px;
		padding: 0;
		border: 0;
		margin: -1px;
		clip: rect(0,0,0,0);
		overflow: hidden;
		position: absolute;
	}

	.custom-check-elem, .custom-radio-elem,
	.custom-check-label, .custom-radio-label {
		cursor: pointer;
		vertical-align: middle;
    margin: 0;
	}

	/* CUSTOM CHECKBOX */
	.custom-check-elem, 
	.custom-radio-elem { 
		display: inline-block;
		position: relative;
		margin-right: 19px;
		border: 1px solid $black;
		width: 20px;
		height: 20px;
	}
	/* CUSTOM RADIOBUTTON */
	.custom-radio-elem { 
		-webkit-border-radius: 999px;
		-moz-border-radius: 999px;
		border-radius: 999px;
	}

	/* CUSTOM CHECKBOX/RADIOBUTTON CHECK */
	.custom-check-elem:before,
	.custom-radio-elem:before {
		content: "\00d7";
		display: none;
		position: absolute;
		top: 0px;
		left: 1px;
		width: 100%;
		height: 100%;
		font-family: verdana, arial, sans-serif;
		font-size: 1.0em;
		line-height: 1.0em;
		font-weight: bold;
		text-align: center;
	}
	/* CUSTOM RADIOBUTTON CHECK */
	.custom-radio-elem:before {
		content: "\2022";
		font-size: 1rem;
		line-height: 1rem;
	}

	/* PSEUDO ELMENT CONTENT FOR CHECKED STATE */
	.custom-check-input:checked + .custom-check-elem:before,
	.custom-radio-input:checked + .custom-radio-elem:before { display: block; }
	/* IE8: needs js-fallback for :checked, don't mix selectors! */
	.custom-check-input.checked + .custom-check-elem:before,
	.custom-radio-input.checked + .custom-radio-elem:before { display: block; }
}
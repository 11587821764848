/* INGRA WD*/
/*Ingra Thin*/
@font-face {
	font-family: ingra-wide;
	font-weight: 100;
	src: url("#{$fa-font-path}/IngraWd-Thin.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Thin.woff") format("woff");
}

/*Ingra Ultra Light*/
@font-face {
	font-family: ingra-wide;
	font-weight:200;
	src: url("#{$fa-font-path}/IngraWd-UltraLight.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-UltraLight.woff") format("woff");
}

/*Ingra Light*/
@font-face {
	font-family: ingra-wide;
	font-weight: 300;
	src: url("#{$fa-font-path}/IngraWd-Light.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Light.woff") format("woff");
}

/* Ingra Regular */
@font-face {
	font-family: ingra-wide;
	font-weight: 400;
	src: url("#{$fa-font-path}/IngraWd-Regular.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Regular.woff") format("woff");
}

/* Ingra Medium */
@font-face {
	font-family: ingra-wide;
	font-weight:500;
	src: url("#{$fa-font-path}/IngraWd-Medium.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Medium.woff") format("woff");	
}

/* Ingra Semi Bold */
@font-face {
	font-family: ingra-wide;
	font-weight:600;
	src: url("#{$fa-font-path}/IngraWd-SemiBold.otf") format("opentype");
         
}

/* Ingra Bold */
@font-face {
	font-family: ingra-wide;
	font-weight:700;
	src: url("#{$fa-font-path}/IngraWd-Bold.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Bold.woff") format("woff");
}

/* Ingra Extra Bold */
@font-face {
	font-family: ingra-wide;
	font-weight:800;
	src: url("#{$fa-font-path}/IngraWd-ExtraBold.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-ExtraBold.woff") format("woff");
}

/* INGRA */
/*Ingra Thin*/
/* DUPLICATE - BY FABIO 
@font-face {
	font-family: ingra-wide;
	font-weight: 100;
	src: url("#{$fa-font-path}/IngraWd-Thin.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Thin.woff") format("woff");
}
*/

/*Ingra Ultra Light*/
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight:200;
	src: url("#{$fa-font-path}/IngraWd-UltraLight.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-UltraLight.woff") format("woff");
}
*/

/*Ingra Light*/
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight: 300;
	src: url("#{$fa-font-path}/IngraWd-Light.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Light.woff") format("woff");
}
*/

/* Ingra Regular */
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight: 400;
	src: url("#{$fa-font-path}/IngraWd-Regular.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Regular.woff") format("woff");
}
*/

/* Ingra Medium */
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight:500;
	src: url("#{$fa-font-path}/IngraWd-Medium.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Medium.woff") format("woff");	
}
*/

/* Ingra Semi Bold */
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight:600;
	src: url("#{$fa-font-path}/IngraWd-SemiBold.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-SemiBold.woff") format("woff");	
}
*/

/* Ingra Bold */
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight:700;
	src: url("#{$fa-font-path}/IngraWd-Bold.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-Bold.woff") format("woff");
}
*/

/* Ingra Extra Bold */
/* DUPLICATE - BY FABIO
@font-face {
	font-family: ingra-wide;
	font-weight:800;
	src: url("#{$fa-font-path}/IngraWd-ExtraBold.woff2") format("woff2"),
         url("#{$fa-font-path}/IngraWd-ExtraBold.woff") format("woff");
}
*/


/*Ingra-2*/
/*Ingra Hair 
@font-face {
	font-family: ingra;
	font-weight:100;
	src: url("#{$fa-font-path}/Ingra-Hair.woff2") format("woff2"),
         url("#{$fa-font-path}/Ingra-Hair.woff") format("woff");
}*/
/*Ingra Book
@font-face {
	font-family: ingra;
	font-weight: 300;
	src: url("#{$fa-font-path}/Ingra-Book.woff2") format("woff2"),
         url("#{$fa-font-path}/Ingra-Book.woff") format("woff");
}*/









#cookieAlert {
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0;
  position: fixed;
  z-index: $zindex-sticky;
  width: 100%;
  background-color: #ffffff;
  border-color: #f2f2f2;
  .btn-info{
    @extend .btn-primary;
  }
}


#searchContainer{
  background-color: $gray-300;

  .typeahead{
    //top: 35px;
    width: 100%;
  }
}


#toTop{
		width: 50px;
		height: 50px;
		border-radius: 100px;
		border: solid 2px #FCAF18;
		background: #322372;
		display: none; 
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 20px;
		left: 18%;
		z-index: 91;
    cursor: pointer;
	}
	#toTop svg{
		fill: #FBAE16;
    margin: 10px;
	}
	@media (min-width: 320px) and (max-width: 767px) {
		#toTop{
			left: 10px;
			bottom: 66px;
		}
  	}

// START: Return to top button
#return-to-top {
  background: $black;
  border: none;
  bottom: 10px;
  display: none;
  height: 35px;
  opacity: 0.8;
  position: fixed;
  right: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 35px;

  i {
    color: $white;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 1;
    i {
      top: -3px;
    }
  }

  @include btn-a-style;
}
// END: Return to top button

#site_footer {
  //background-color: $gray-800;
  //color: $gray-500; Marbel footer

  a {
		&.active{
			text-decoration:underline;
		}
    &:hover, &:visited, &:active {
      text-decoration: none;
    }
  }
}

.navbar-brand {
	width: 168px;
  @include media-breakpoint-down(xs) {
    width:124px;
  }
	/*@include media-breakpoint-down(md) {
		left: 50%;
		margin-left: -52px;
		position: absolute;
		top:0.5rem;
	}
	@include media-breakpoint-down(sm) {
		width:105px;
	}
*/
}



.navbar-dark {
  .navbar-nav {
    .nav-link {
      color:rgba(255,255,255,.8);
      font-family: $font-family-ingra-wide;
      font-weight: 300;
      &:hover,.active{
        color:rgba(255,255,255,1);
        font-weight: 400;
      }
    }
  }
}
#filter_char_1204{
  >.row{
    flex-flow: nowrap;
    .col-6{
      padding-right:0;
      min-width: 140px;
    }
  }
}
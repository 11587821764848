.landing-iva-contact{
	border: 1px solid #F2F6F5;
	box-sizing: border-box;
	border-radius: 8px;
	padding:1.5rem 0rem;
}

//////
@include media-breakpoint-up(md){
	.column-count-2{column-count: 2;column-gap: 170px;}
	.column-count-3{column-count: 3;column-gap: 70px;}
}


.landing-page, .landing-how-works{
	.title{
		color: $white;
		font-family: $font-family-ingra-wide;
		font-weight: 500;
		font-size: 35px;
		line-height: 50px;
		padding-top: 90px;
		@include media-breakpoint-down(sm){
			font-size: 28px;
			line-height: 50px;
			padding-top: 40px;
		}
	}
	.subtitle{
		color: $white;
	}
	.description{
		color: $white;
		font-weight: 300;
		font-size: 16px;
		//line-height: 25px;
	}
}

.component_696182{
	padding-bottom: 6rem;
}



#section-carousel-696189{
	.tns-outer{
		
    margin-right: -15px;
    margin-left: -15px;

		#tns1-mw{
			.tns-inner{
				#tns1{
					    width: calc(150%)!important;
					a.tns-item{
    					width: initial;
							img{
								@include media-breakpoint-down(sm){
									max-width: 390px;
									min-height: 400px;
							}
						}
					}
				}
			}
		}
		.tns-nav{
			text-align: center;
			padding: 50px 0px 10px ;
			button{
				border-radius: 50%;
				background-color: #d9d9d9;
				width: 15px;
				height: 15px;
				border: none;
				margin-right: 20px;
				&.tns-nav-active{
					background-color: $blue;
				}
			}
		}
	}
}

//How it works Landing page 

#site_main{
	.landing-how-works{
		.title{
			padding-top: 70px;
			padding-bottom: 40px;
		}
		.landing-how-works-box-info{
			padding-bottom: 123px;
			@include media-breakpoint-down(sm) {
				padding-bottom: 53px;
			}
		}
		div:nth-child(2){
			//padding-left: 0;
			//padding-right: 0;
		}
		div.component-textImage{
			.row{
				margin-left: 0;
				margin-right: 0;
				div.textCnt{
					padding: 1vw 7.5vw;
					@include media-breakpoint-down(sm) {
						padding: 7.5vw;
					}
					@media only screen 
					and (min-device-width: 768px) 
					and (max-device-width: 1024px) 
					and (orientation: portrait) 
					and (-webkit-min-device-pixel-ratio: 1) {
					    padding: 5vw 7.5vw;
					}
				}
				picture{
					img{
						width: 100% !important;
					}
				}
			}
			/*@include media-breakpoint-down(sm) {
				padding-top: 40px;
				padding-bottom: 40px;
			}*/
		}
		.component-box-cerca{
			padding-bottom: 7rem;
			@include media-breakpoint-down(sm) {
				padding-bottom: 0.5rem;
			}
			.text{
			    padding: 25px 25px 30px 25px;
				p{
					padding-right: 40px;
				}
				a.btn{
					margin-top: 50px !important;
				}
			}
			/*.carousel-indicators{
				@include media-breakpoint-down(sm) {

				}
			}*/
		}
	}
	.row_how_works_landing{
		padding-top: 40px;
		
			@include media-breakpoint-down(sm) {
				padding-top: 0px;
				
			}
		h3.title{
				font-weight: 600;
				font-size: 35px;
				line-height: 50px;
			}
		.card.card_how_works_landing{
			.card-header{
				border-top-right-radius: 20px;
				border-top-left-radius: 20px;
				background-color: $white !important;
				padding: 2rem 1rem;
				max-height: 97px;
			}
			&.how_works_696590{
				.card-header{
					
						padding-top:1rem;
					
				}
			}
			.card-body{
				@include media-breakpoint-up(sm) {
					min-height: 320px;
				}
			}
		} 
	}

	#component-696888{
		@include media-breakpoint-down(sm) {
			padding-top: 50px;
		}
	}

	#accordion-hiw{
		padding-top: 150px;
		@include media-breakpoint-down(sm) {
		    padding-top: 50px;
		}
		.card{
			.card-header{
				background-color: $white !important;
				padding: 1.25rem;
				@include media-breakpoint-down(sm) {
					padding: 25px 1.25rem;
				}
				a{
					font-weight: 300;
					font-size: 24px;
					line-height: 72px;
					@include media-breakpoint-down(sm) {
		    			font-size: 20px;
						line-height: 1.2;
						padding: 0;
					}
					&::after{
						transform: scale(.5);
						top: 20px;
						right: 46px;
						height: 45px;
						width: 45px;
						@include media-breakpoint-down(sm) {		
							transform: scale(.25);
							top: 26px;
							right: 14px;
						}
					}
					&.collapsed::after{
						transform: scale(1.5);
						top: 20px;
						right: 12px;
						height: 45px;
						width: 45px;
						@include media-breakpoint-down(sm) {
		    				transform: scale(1);
							top: 27px;
							right: -8px;
						}
					}
					&:hover{
						text-decoration: none;
					}
				}
			}
			.card-body{
				font-weight: 400;
				font-size: 16px;
				line-height: 25px;
				background-color: white;
				.row{
					margin-bottom: 50px;
				}
			}
			&:first-of-type{
				border-top: 1px solid #dee2e6!important;
			}
			div.show{
				    border-bottom: 1px solid #dee2e6;
			}
		} 
	}
}

#accordion-hiw{
	.help-title.collapsed::after {
		content: url(../../images/icons/iconmonstr-plus-thin.svg);
	}
	.help-title::after {
		content: url("../../images/icons/accordion-close.svg");
	}
}


// end of How it works landing page

.badge-chi-siamo{
	border: solid 2px #322372; border-radius: 20px;
	height: 100%;
}